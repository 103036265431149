<template>
    <div class="survey-page">
        <p class="title">Witaj w załodze Cabbie!</p>
        <p class="second-title">Prosimy o wypełnienie formularza początkowego.</p>

        <div class="survey">
            <div class="question-container">
                <p class="question">Uzupełnij swoje dane:</p>
                <p class="subtitle">Dane osobowe:</p>
                <div class="input-row">
                    <input type="text" v-model="phone_number" placeholder="NR TELEFONU" class="input-field" />
                    <input type="text" v-model="pesel" placeholder="NR PESEL" class="input-field pesel" />
                    <input type="text" v-model="bank_name" placeholder="NAZWA BANKU" class="input-field" />
                    <input type="text" v-model="bank_account_number" placeholder="NR KONTA BANKU" class="input-field" />
                </div>
                <p class="subtitle">Dane adresowe:</p>
                <div class="input-row">
                    <input type="text" v-model="postal_code" placeholder="KOD POCZTOWY" class="input-field" />
                    <input type="text" v-model="living_city" placeholder="MIASTO" class="input-field" />
                    <input type="text" v-model="street" placeholder="ULICA" class="input-field" />
                    <input type="text" v-model="building_number" placeholder="NR BUDYNKU/MIESZKANIA"
                        class="input-field" />
                </div>
            </div>

            <div class="question-container" id="city-question-container">
                <p class="question">Wybierz miasto:</p>
                <div class="button-group">
                    <button class="answer-button" :class="{ active: selectedCity === 'Biała Podlaska' }"
                        @click="setCity('Biała Podlaska')">Biała Podlaska</button>
                    <button class="answer-button" :class="{ active: selectedCity === 'Białystok' }"
                        @click="setCity('Białystok')">Białystok</button>
                    <button class="answer-button" :class="{ active: selectedCity === 'Suwałki' }"
                        @click="setCity('Suwałki')">Suwałki</button>
                </div>
            </div>

            <div class="question-container">
                <p class="question">Wybierz aplikacje:</p>
                <div class="checkbox-group">
                    <label class="checkbox-label"
                        :class="{ 'normal-style': selectedCity === 'Biała Podlaska' || selectedCity === 'Suwałki' || selectedCity === 'Białystok' }">
                        <input type="checkbox" value="Bolt" v-model="bolt_driver" />
                        <span></span>
                        Bolt
                    </label>
                    <label class="checkbox-label" :class="{ 'normal-style': selectedCity === 'Białystok' }">
                        <input type="checkbox" value="Uber" v-model="uber_driver" />
                        <span></span>
                        Uber
                    </label>
                    <label class="checkbox-label" :class="{ 'normal-style': selectedCity === 'Białystok' }">
                        <input type="checkbox" value="FreeNow" v-model="freenow_driver" />
                        <span></span>
                        FreeNow
                    </label>
                </div>
            </div>

            <div class="question-container" id="first-question-container">
                <p class="question">Czy jesteś aktywnym kierowcą aplikacji Taxi?</p>
                <div class="button-group">
                    <button class="answer-button" :class="{ active: selectedDriverOption === 'Tak' }"
                        @click="setDriverOption('Tak')">Tak</button>
                    <button class="answer-button" :class="{ active: selectedDriverOption === 'Nie' }"
                        @click="setDriverOption('Nie')">Nie</button>
                </div>
            </div>

            <div class="question-container" id="car-question-container">
                <p class="question">Samochód:</p>
                <div class="button-group">
                    <button class="answer-button" :class="{ active: selectedCarType === 'Własny' }"
                        @click="setCarType('Własny')">Własny</button>
                    <div class="additional-question" id="taxi-question">
                        <p class="question">Czy posiada wpis TAXI w dowodzie?</p>
                        <div class="button-group">
                            <button class="additional-button" :class="{ active: selectedTaxiRegistry === 'Tak' }"
                                @click="setTaxiRegistry('Tak')">Tak</button>
                            <button class="additional-button" :class="{ active: selectedTaxiRegistry === 'Nie' }"
                                @click="setTaxiRegistry('Nie')">Nie</button>
                        </div>
                    </div>
                    <button class="answer-button" :class="{ active: selectedCarType === 'Chcę wynająć' }"
                        @click="setCarType('Chcę wynająć')">Chcę wynająć</button>
                </div>
            </div>

            <div class="question-container">
                <p class="question">Jaki jest Twój obecny status zawodowy?</p>
                <div class="button-group">
                    <button class="answer-button" :class="{ active: selectedJobStatus === 'Student' }"
                        @click="setJobStatus('Student')">Student</button>
                    <button class="answer-button" :class="{ active: selectedJobStatus === 'Inna umowa zlecenie' }"
                        @click="setJobStatus('Inna umowa zlecenie')">Inna umowa zlecenie</button>
                    <button class="answer-button" :class="{ active: selectedJobStatus === 'Umowa o pracę' }"
                        @click="setJobStatus('Umowa o pracę')">Umowa o pracę</button>
                    <button class="answer-button" :class="{ active: selectedJobStatus === 'B2B' }"
                        @click="setJobStatus('B2B')">B2B</button>
                    <button class="answer-button" :class="{ active: selectedJobStatus === 'Bezrobotny/a' }"
                        @click="setJobStatus('Bezrobotny/a')">Bezrobotny/a</button>
                </div>
            </div>

            <div class="question-container">
                <p class="question">Jak szybko chcesz rozpocząć pracę?</p>
                <div class="button-group">
                    <button class="answer-button" :class="{ active: selectedStartTime === 'Jak najszybciej' }"
                        @click="setStartTime('Jak najszybciej')">Jak najszybciej</button>
                    <button class="answer-button" :class="{ active: selectedStartTime === 'Do dwóch tygodni' }"
                        @click="setStartTime('Do dwóch tygodni')">Do dwóch tygodni</button>
                    <button class="answer-button" :class="{ active: selectedStartTime === 'Do miesiąca' }"
                        @click="setStartTime('Do miesiąca')">Do miesiąca</button>
                </div>
            </div>

            <div class="question-container">
                <p class="question">Ile czasu tygodniowo chcesz przeznaczyć na pracę jako kierowca Taxi?</p>
                <input type="text" class="text-input" v-model="weeklyHours" placeholder="Wpisz przedział godzinowy">
            </div>

            <div class="question-container">
                <p class="question">Skąd dowiedziałeś się o Cabbie?</p>
                <div class="button-group">
                    <button class="answer-button" :class="{ active: selectedSource === 'Facebook' }"
                        @click="setSource('Facebook')">Facebook</button>
                    <button class="answer-button" :class="{ active: selectedSource === 'Instagram' }"
                        @click="setSource('Instagram')">Instagram</button>
                    <button class="answer-button" :class="{ active: selectedSource === 'Google' }"
                        @click="setSource('Google')">Google</button>
                    <button class="answer-button" :class="{ active: selectedSource === 'Z polecenia' }"
                        @click="setSource('Z polecenia')">Z polecenia</button>
                    <button class="answer-button" :class="{ active: selectedSource === 'Inne' }"
                        @click="setSource('Inne')">Inne</button>
                </div>
            </div>

            <div v-if="selectedSource === 'Z polecenia'" class="question-container">
                <p class="question">Wprowadź kod:</p>
                <div class="referral-group">
                    <input type="text" v-model="referralCode" placeholder="Wpisz kod polecenia" class="input-field" />
                </div>
            </div>

            <button class="send" @click="submitSurvey">WYŚLIJ</button>
            <button class="send" @click="skipSurvey">POMIŃ</button>
        </div>
        <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div v-if="loading" class="loading-modal">
                <LoaderComponent class="loading" />
            </div>
        </transition>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import apiService from '@/apiService';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';

export default {
    components: {
        LoaderComponent
    },
    data() {
        return {
            loading: false,
            selectedCity: null,
            selectedDriverOption: null,
            selectedCarType: null,
            selectedTaxiRegistry: null,
            selectedJobStatus: null,
            selectedStartTime: null,
            weeklyHours: '',
            selectedSource: null,
            phone_number: '',
            pesel: '',
            bank_name: '',
            bank_account_number: '',
            postal_code: '',
            living_city: '',
            street: '',
            building_number: '',
            bolt_driver: false,
            uber_driver: false,
            freenow_driver: false,
            referralCode: '',
        };
    },

    methods: {
        setCity(city) {
            this.selectedCity = city;
            this.bolt_driver = false;
            this.uber_driver = false;
            this.freenow_driver = false;
        },

        setDriverOption(option) {
            this.selectedDriverOption = option;
        },

        setCarType(type) {
            this.selectedCarType = type;
            if (type === 'Własny') {
                document.getElementById('taxi-question').style.display = 'flex';
                setTimeout(() => {
                    document.getElementById('taxi-question').classList.add('expand');
                }, 10);
                this.isActive = null;
            } else {
                const taxiQuestion = document.getElementById('taxi-question');
                taxiQuestion.classList.remove('expand');
                setTimeout(() => {
                    taxiQuestion.style.display = 'none';
                }, 300);
                this.selectedTaxiRegistry = null;
                this.isActive = null;
            }
        },

        setTaxiRegistry(option) {
            this.selectedTaxiRegistry = option;
        },

        setJobStatus(status) {
            this.selectedJobStatus = status;
        },

        setStartTime(time) {
            this.selectedStartTime = time;
        },

        setSource(source) {
            this.selectedSource = source;
        },

        isFormValid() {
            return this.selectedCity && this.selectedDriverOption && this.selectedCarType &&
                this.selectedJobStatus && this.selectedStartTime && this.weeklyHours &&
                this.selectedSource && this.phone_number && this.pesel &&
                this.bank_name && this.bank_account_number && this.postal_code &&
                this.living_city && this.street && this.building_number;
        },

        async submitSurvey() {
            if (!this.isFormValid()) {
                Swal.fire({
                    title: 'Błąd!',
                    text: 'Proszę uzupełnić wszystkie pola przed wysłaniem formularza.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                return;
            }

            this.loading = true;

            const surveyAnswers = {
                city: this.selectedCity,
                isDriver: this.selectedDriverOption,
                carType: this.selectedCarType,
                taxiRegistry: this.selectedTaxiRegistry,
                jobStatus: this.selectedJobStatus,
                startTime: this.selectedStartTime,
                weeklyHours: this.weeklyHours,
                foundVia: this.selectedSource,
                phone_number: this.phone_number,
                pesel: this.pesel,
                bank_name: this.bank_name,
                bank_account_number: this.bank_account_number,
                postal_code: this.postal_code,
                living_city: this.living_city,
                street: this.street,
                building_number: this.building_number,
                bolt_driver: this.bolt_driver,
                uber_driver: this.uber_driver,
                freenow_driver: this.freenow_driver
            };

            try {
                await apiService.post('/submit-survey', surveyAnswers);

                if (this.selectedSource === 'Z polecenia' && this.referralCode) {
                    try {
                        await apiService.post(`/gold-program/use-code/${this.referralCode}`);
                        Swal.fire({
                            title: 'Sukces!',
                            text: 'Kod został pomyślnie użyty.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        });
                    } catch (error) {
                        let errorMessage = 'Wystąpił problem podczas przetwarzania kodu.';
                        if (error.response) {
                            switch (error.response.status) {
                                case 404:
                                    errorMessage = 'Kod nie istnieje.';
                                    break;
                                case 400:
                                    errorMessage = error.response.data.error || 'Wystąpił błąd podczas próby użycia kodu.';
                                    break;
                                default:
                                    errorMessage = error.response.data.message || 'Nieznany błąd.';
                            }
                        }
                        Swal.fire({
                            title: 'Błąd',
                            text: errorMessage,
                            icon: 'error',
                            confirmButtonText: 'OK',
                        });
                        return;
                    }
                }

                Swal.fire({
                    title: 'Dziękujemy!',
                    text: 'Twoje odpowiedzi zostały zapisane.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    this.$store.dispatch('fetchFirstLoginStatus');
                    this.$router.push({
                        name: 'Home'
                    });
                });
            } catch (error) {
                Swal.fire({
                    title: 'Błąd!',
                    text: 'Wystąpił problem podczas zapisywania odpowiedzi.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } finally {
                this.loading = false;
            }
        },
        skipSurvey() {
            this.$router.push({
                name: 'Home'
            });
        },
        beforeEnter(el) {
            el.style.opacity = 0;
        },
        enter(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.6s';
            el.style.opacity = 1;
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 0;
            done();
        },
    }
};
</script>

<style lang="scss" scoped>
.title {
    text-align: center;
    font-size: 36px;
    font-family: 'Roboto-Light', sans-serif;
    color: $primary-color;
    margin-top: 100px;
    padding: 20px 0;
}

.second-title {
    text-align: center;
    font-size: 24px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $title-light-font;
    padding: 15px 0;
}

.survey {
    background-color: $secondary-color;
    height: auto;
    margin: 100px auto;
    max-width: 800px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.question-container {
    background-color: $secondary-color;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    padding: 20px;
    border-radius: 20px;
    margin: 20px 0;
}

.additional-question {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    padding-bottom: 30px;
    padding-left: 50px;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.additional-question.expand {
    max-height: 500px;
    opacity: 1;
}

.question {
    font-size: 24px;
    font-family: 'Roboto-Light', sans-serif;
    color: $title-light-font;
    margin-bottom: 10px;
    margin-top: 10px;
}

.subtitle {
    font-size: 18px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $title-light-font;
    margin-bottom: 10px;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.referral-group {
    display: flex;
    gap: 10px;
}

.answer-button {
    padding: 10px 20px;
    background-color: $quaternary-color;
    color: $title-light-font;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 125px;
    text-align: left;
}

.answer-button:hover {
    background-color: $primary-color;
    color: $tertiary-color;
}

.answer-button.active {
    background-color: $primary-color;
    color: $tertiary-color;
}

.additional-button {
    padding: 10px 20px;
    background-color: $quaternary-color;
    color: $title-light-font;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 100px;
    text-align: left;
}

.additional-button:hover {
    background-color: $primary-color;
    color: $tertiary-color;
}

.additional-button.active {
    background-color: $primary-color;
    color: $tertiary-color;
}

.text-input,
.input-field {
    padding: 10px 20px;
    background-color: $text-container-color;
    color: $title-light-font;
    border: 2px solid transparent;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    width: 100%;
    max-width: 200px;
    margin-top: 5px;
}

.text-input::placeholder,
.input-field::placeholder {
    color: $placeholder-color;
}

.text-input:focus,
.input-field:focus {
    border-color: $primary-color;
    outline: none;
}

.send {
    background-color: $primary-color;
    color: $tertiary-color;
    border: 2px solid $primary-color;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 16px;
    padding: 10px 20px;
    transition: all 0.3s ease;
    margin-top: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.send:hover {
    color: $white;
    background-color: $quaternary-color;
    border: 2px solid $quaternary-color;
}

.input-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
}

.normal-style {
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 8px;
    color: $white;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 16px;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
}

.checkbox-label.normal-style {
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
}

input[type="checkbox"] {
    display: none;
}

span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid $primary-color;
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
    background-color: $quaternary-color;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

input[type="checkbox"]:checked+span {
    background-color: $primary-color;
    border-color: darken($primary-color, 10%);
}

input[type="checkbox"]:checked+span::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    width: 10px;
    height: 6px;
    border: solid $tertiary-color;
    border-width: 0 0 2px 2px;
    transform: translate(-50%, -40%) rotate(-45deg);
}

@media (max-width: 768px) {
    .title {
        font-size: 28px;
    }

    .second-title {
        font-size: 18px;
        padding: 20px;
    }

    .survey {
        margin: 50px auto;
        padding: 10px;
        max-width: 90%;
    }

    .question-container {
        width: 80%;
        padding: 15px;
        margin: 15px 0;
    }

    .question {
        font-size: 18px;
    }

    .answer-button {
        padding: 8px 16px;
        font-size: 14px;
        max-width: 125px;
    }

    .text-input {
        padding: 8px 16px;
    }

    .send {
        font-size: 16px;
        padding: 8px 16px;
        margin-top: 15px;
    }
}
</style>
