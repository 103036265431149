<template>
<div class="umowa-najmu">
    <button class="back-button" @click="goBack">
        <i class="fas fa-arrow-left"></i>
    </button>
    <p class="title">Umowa Najmu</p>
    <div class="umowa-content" ref="content">
        <div class="company-info">
            <p class="spaced">Cabbie Spółka z ograniczoną odpowiedzialnością</p>
            <p class="spaced">Węgierska 49, 15-641 Krupniki</p>
            <p class="spaced">NIP: 966-218-71-43</p>
        </div>
        <div class="contract-details">
            <p class="spaced"><strong>Węgierska 49, 15-641 Krupniki, {{ currentDate }}</strong></p>
            <p class="spaced">Miejscowość, data</p>
            <p class="spaced"><strong>???</strong></p>
            <p class="spaced">Nr umowy</p>
        </div>
        <p class="subtitle">Pomiędzy:</p>
        <div class="lessee">
            <p class="subtitle">NAJEMCĄ:</p>
            <p class="spaced">Nazwa: Cabbie sp. z o. o.</p>
            <p class="spaced">Adres: Węgierska 49, 15-641 Krupniki</p>
            <p class="spaced">NIP: 9662187143</p>
            <p class="spaced">REGON: 52741917</p>
            <p class="spaced">Tel.: 500061435</p>
            <p class="spaced">E-mail: biuro@cabbie.pl</p>
        </div>
        <div class="lessor">
            <p class="subtitle">WYNAJMUJĄCYM:</p>
            <p class="spaced">Nazwa / osoba: {{ user.first_name }} {{ user.last_name }}</p>
            <p class="spaced">Adres: ul. {{ user.street }} {{ user.building_number }}, {{ user.postal_code }} {{ user.living_city }}</p>
            <p class="spaced">NIP / PESEL: {{ user.pesel }}</p>
            <p class="spaced">E-mail: {{ user.email }}</p>
            <p class="spaced">Bank: {{ user.bank_name }}</p>
            <p class="spaced">Nr rachunku: {{ user.bank_account_number }}</p>
        </div>
        <div class="rental-object">
            <p class="subtitle">Przedmiot najmu:</p>
            <p class="spaced">Przedmiot najmu: Samochód</p>
            <p class="spaced">Marka: {{ car.brand }}</p>
            <p class="spaced">Model: {{ car.model }}</p>
            <p class="spaced">Rok produkcji: {{ car.year }}</p>
            <p class="spaced">Nr VIN: {{ car.vin }}</p>
            <p class="spaced">Nr rejestracji: {{ car.registration_number }}</p>
        </div>
        <div class="description">
            <h3>§1</h3>
            <p class="spaced">1. Przedmiotem Umowy jest najem Pojazdu o danych jak w tabeli wyżej (dalej: Pojazd) przez
                Wynajmującego na rzecz Najemcy na warunkach określonych w niniejszej Umowie oraz Regulaminie Serwisu
                udostępnionym przez Najemcę za pośrednictwem platformy trafficbstok.pl bądź przesłaniem na adres e-mail
                dalej: Regulamin).</p>
            <p class="spaced">2. Wynajmujący oświadcza, iż zapoznał się z Regulaminem Serwisu i zobowiązuje się do jego
                bezwzględnego przestrzegania.</p>
            <p class="spaced">3. Wynajmujący oświadcza, iż jest właścicielem, leasingobiorcą bądź wynajmującym Pojazd i
                jest uprawniony do samodzielnego rozporządzania Pojazdem.</p>
            <p class="spaced page-break">4. Wynajmujący oświadcza, że Pojazd jest ubezpieczony w pełnym wymaganym zakresie oraz
                jest wolny od wad fizycznych i prawnych, w tym praw osób trzecich, nie toczy się w jego zakresie
                jakiekolwiek postępowanie sądowe, administracyjne lub egzekucyjne, żaden podmiot nie rości sobie
                względem Pojazdu żadnych praw oraz nadaje się dla realizacji celu umowy, o którym mowa w § 1 ust. 1.</p>
            <p class="spaced">5. Najemca na mocy niniejszej Umowy ma prawo oddać Pojazd w podnajem lub użyczenie
                dowolnie wybranemu przez siebie podmiotowi, a zgoda Wynajmującego nie jest w tym zakresie wymagana.</p>
            <h3>§2</h3>
            <p class="spaced">1. Najemca zobowiązuje się płacić na rzecz Wynajmującego ryczałtowy czynsz najmu w
                okresach tygodniowych, płatny z dołu, w wysokości ustalonej adekwatnie do stopnia eksploatacji Pojazdu w
                danym okresie rozliczeniowym, pomniejszony o wszelkie konieczne do poniesienia należności, w tym o
                koszty i opłaty określone w treści Regulaminu Serwisu, a także o wszelkie opłaty naliczane zgodnie z
                przepisami prawa, daniny publiczne czy dodatkowe opłaty, które zobligowany był ponieść Najemca.</p>
            <p class="spaced">2. Płatność, o której mowa w ust. 1 będzie następowała w okresach tygodniowych na rachunek
                bankowy Wynajmującego o polskim numerze IBAN wskazany w tabeli wyżej, lub gotówką w kasie Najemcy.</p>
            <p class="spaced">3. Za dzień dokonania zapłaty uznaje się datę obciążenia rachunku bankowego Najemcy lub
                datę wypłaty w kasie Najemcy.</p>
            <h3>§3</h3>
            <p class="spaced">1. Najemca nie ponosi odpowiedzialności za działania lub zaniechania Wynajmującego.
                Odpowiedzialność w tym zakresie spoczywa na Wynajmującym.</p>
            <p class="spaced">2. Wynajmujący ma obowiązek samodzielnie rozliczać się z dochodu uzyskanego z tytułu najmu
                z właściwym Urzędem Skarbowym, na obowiązujących w Rzeczpospolitej Polskiej zasadach. Podstawą
                opodatkowania jest czynsz w wysokości wypłaconej Wynajmującemu przez Najemcę zgodnie z zasadami
                wskazanymi w § 2 tj. w wysokości faktycznie przekazanej przelewem na rachunek bankowy lub gotówką w
                kasie na podstawie pokwitowania odbioru, przy czym przy rozliczeniach za dany miesiąc kalendarzowy z
                Urzędem Skarbowym Wynajmujący powinien uwzględnić wszystkie dochody uzyskane od Najemcy w danym miesiącu
                tytułem Umowy Najmu, bez względu na faktyczną formę (gotówkową /bezgotówkową) ich uzyskania.</p>
            <h3>§4</h3>
            <p class="spaced">1. Umowa zostaje zawarta na czas nieokreślony od daty jej zawarcia.</p>
            <p class="spaced">2. Każda ze Stron ma prawo wypowiedzieć́ Umowę̨, z zachowaniem 1 tygodniowego okresu
                wypowiedzenia. Wypowiedzenie powinno nastąpić́ w formie pisemnej pod rygorem nieważności, z tymże za
                zachowanie formy pisemnej uważa się również przesłanie wiadomości e-mail na adres poczty elektronicznej
                wskazany w tabeli wyżej.</p>
            <p class="spaced">3. Każdej ze Stron przysługuje prawo do rozwiązania Umowy ze skutkiem natychmiastowym w
                przypadku niewykonywania lub nienależytego wykonywania Umowy przez drugą Stronę. W takim przypadku
                Strona składająca oświadczenie o natychmiastowym rozwiązaniu Umowy, pod rygorem nieważności na piśmie,
                zobowiązana jest wskazać przyczynę rozwiązania oraz jej uzasadnienie.</p>
            <h3>§5</h3>
            <p class="spaced">1. Wszelkie zmiany niniejszej Umowy powinny być dokonywane w formie pisemnej, pod rygorem
                nieważności, chyba że co innego wynika z treści Umowy.</p>
            <p class="spaced">2. W sprawach nie uregulowanych niniejszą Umową obowiązują przepisy prawa polskiego, w tym
                w szczególności ustawy - Kodeks cywilny.</p>
            <p class="spaced">3. Ewentualne spory, jakie mogą wyniknąć z realizacji Umowy, Strony poddają
                rozstrzygnięciu właściwym dla siedziby Najemcy sadom powszechnym.</p>
            <p class="spaced">4. Umowa sporządzona została w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej
                ze Stron.</p>
        </div>
        <div class="signature-section">
            <table class="signature-table">
                <tr>
                    <td>
                        <p class="signature-title">Zleceniobiorca:</p>
                    </td>
                    <td>
                        <p class="signature-title">Zleceniodawca:</p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="action-button">
        <button class="generate-pdf" @click="generatePDF">POBIERZ PDF</button>
        <button class="autenti-confirmation" @click="signWithAutenti">POTWIERDZAM DANE I PODPISUJĘ AUTENTI (JUŻ WKRÓTCE)</button>
    </div>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import {
    mapGetters,
} from 'vuex';

export default {
    data() {
        return {
            currentDate: ''
        }
    },
    computed: {
        ...mapGetters(['getUser', 'getCar']),
        user() {
            return this.getUser || {};
        },
        car() {
            return this.getCar || {};
        }
    },
    methods: {
        generatePDF() {
            const element = this.$refs.content;
            const options = {
                margin: 1,
                filename: 'Umowa_Najmu.pdf',
                image: {
                    type: 'jpeg',
                    quality: 0.98
                },
                html2canvas: {
                    scale: 2
                },
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait'
                }
            };

            html2pdf()
                .from(element)
                .set(options)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages();
                    const title = 'Umowa Najmu';

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);

                        if (i === 1) {
                            pdf.setFontSize(14);
                            pdf.setFont("helvetica", "bold");
                            pdf.text(title, pdf.internal.pageSize.getWidth() / 2, 0.5, {
                                align: 'center'
                            });
                        }

                        const x = pdf.internal.pageSize.getWidth() / 2;
                        const y = pdf.internal.pageSize.getHeight() - 0.5;
                        pdf.setFontSize(8);
                        pdf.setFont("helvetica", "normal");
                        pdf.text(`${i} z ${totalPages}`, x, y, {
                            align: 'center'
                        });
                    }
                })
                .save();
        },
        setCurrentDate() {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            this.currentDate = `${day}.${month}.${year} r.`;
        },
        goBack() {
            this.$router.go(-1);
        }
    },
    mounted() {
        this.setCurrentDate();
    }
};
</script>

<style lang="scss" scoped>
.page-break {
    page-break-after: always;
}

.umowa-najmu {
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
    margin: 100px;
}

.umowa-content {
    text-align: justify;
    font-family: Arial, sans-serif;
}

.title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.subtitle {
    font-size: 11px;
    font-weight: bold;
}

.lessee,
.lessor,
.rental-object {
    font-size: 11px;
}

.signature-section {
    font-size: 11px;
    text-align: center;
}

.signature-table {
    width: 100%;
    border-collapse: collapse;
}

.signature-table td {
    width: 50%;
    padding: 20px;
    vertical-align: bottom;
    text-align: center;
}

.signature-title {
    font-weight: bold;
    font-size: 11px;
}

.flex-div {
    display: flex;
    gap: 100px;
}

.company-info {
    text-align: left;
    font-weight: bold;
    font-size: 11px;
    line-height: 0.1;
}

.contract-details {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 11px;
    line-height: 0.1;
}

.description {
    margin-left: 20px;
    font-size: 11px;
}

h3 {
    text-align: center;
}

.spaced {
    margin-bottom: 0.4em;
}

.subsection {
    margin-left: 30px;
}

.action-button {
    display: flex;
    gap: 10px;
}

.generate-pdf,
.autenti-confirmation {
    padding: 10px 15px;
    background-color: $primary-color;
    color: $tertiary-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.generate-pdf:hover,
.autenti-confirmation:hover {
    background-color: $text-container-color;
    color: $primary-color;
}

.back-button {
    position: absolute;
    background-color: transparent;
    border: none;
    color: $primary-color;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.back-button i {
    margin-right: 5px;
}

.back-button:hover {
    color: $secondary-color;
}

@media (max-width: 768px) {
    .title {
        margin-top: 100px;
    }

    .umowa-najmu {
        margin: 30px;
    }

    .flex-div {
        flex-direction: column;
        gap: 0;
    }

    .company-info,
    .contract-details {
        line-height: 0.2;
    }

    .description {
        margin-left: 0;
    }
}
</style>