<template>
  <div
    ref="offerSection"
    class="offer"
    :class="{ 'is-visible': isVisible }"
  >
    <p class="title">CO CI OFERUJEMY?</p>
    <div class="row">
      <div class="col" v-for="(offer, index) in offers" :key="index">
        <img :src="offer.image" :alt="offer.title">
        <p class="offer-title">{{ offer.title }}</p>
        <p class="description">{{ offer.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

import trustImage from '@/assets/images/trust.svg';
import researchBookImage from '@/assets/images/Research Book.svg';
import employeeRateImage from '@/assets/images/Employee Rate.svg';

const offers = ref([
  {
    image: trustImage,
    title: 'ZAUFANIE',
    description: 'Budujemy relacje oparte na zaufaniu i partnerskiej współpracy.',
  },
  {
    image: researchBookImage,
    title: 'TRANSPARENTNOŚĆ',
    description: 'Stawiamy na przejrzystość w rozliczeniach i działaniu.',
  },
  {
    image: employeeRateImage,
    title: 'WSPÓLNY ROZWÓJ',
    description: 'Dążymy do ciągłego rozwoju, zarówno floty jak i naszej marki.',
  },
]);

const isVisible = ref(false);
const offerSection = ref(null);

const handleIntersection = (entries) => {
  if (entries[0].isIntersecting) {
    isVisible.value = true;
  }
};

let observer;

onMounted(() => {
  observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.2,
  });
  if (offerSection.value) {
    observer.observe(offerSection.value);
  }
});

onUnmounted(() => {
  if (observer && offerSection.value) {
    observer.unobserve(offerSection.value);
  }
});
</script>

<style lang="scss" scoped>
.offer {
  padding: 20px 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  text-align: center;
  font-size: 36px;
  font-family: 'Roboto-Light', sans-serif;
  color: $primary-color;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.col {
  flex: 1;
  max-width: 250px;
  text-align: center;
}

img {
  max-width: 80%;
  height: auto;
  margin-bottom: 15px;
}

.offer-title {
  color: $white;
  font-family: 'Roboto-Light', sans-serif;
  font-size: 22px;
  margin-bottom: 10px;
}

.description {
  color: $white;
  font-family: 'Roboto-Extra-Light', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

@media (max-width: 768px) {
  .offer {
    padding: 10px 0;
    height: auto;
  }

  .title {
    font-size: 30px;
  }

  img {
    transform: scale(0.7);
  }

  .offer-title {
    font-size: 20px;
  }

  .description {
    font-size: 18px;
    padding: 0 10px;
  }

  .row {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .col {
    max-width: 90%;
  }

  .col:last-child {
    margin-bottom: 80px;
  }
}
</style>s