<template>
<div class="umowa-zlecenie">
    <button class="back-button" @click="goBack">
        <i class="fas fa-arrow-left"></i>
    </button>
    <p class="title">Umowa Zlecenie</p>
    <div class="umowa-content" ref="content">
        <div class="company-info">
            <p class="spaced">Cabbie Spółka z ograniczoną odpowiedzialnością</p>
            <p class="spaced">Węgierska 49, 15-641 Krupniki</p>
            <p class="spaced">NIP: 966-218-71-43</p>
        </div>
        <div class="contract-details">
            <p class="spaced"><strong>Węgierska 49, 15-641 Krupniki, {{ currentDate }}</strong></p>
            <p class="spaced">Miejscowość, data</p>
            <p class="spaced"><strong>???</strong></p>
            <p class="spaced">Nr umowy</p>
        </div>
        <p class="subtitle">Pomiędzy:</p>
        <div class="lessee">
            <p class="subtitle">NAJEMCĄ:</p>
            <p class="spaced">Nazwa: Cabbie sp. z o. o.</p>
            <p class="spaced">Adres: Węgierska 49, 15-641 Krupniki</p>
            <p class="spaced">NIP: 9662187143</p>
            <p class="spaced">REGON: 52741917</p>
            <p class="spaced">Tel.: 500061435</p>
            <p class="spaced">E-mail: biuro@cabbie.pl</p>
        </div>
        <div class="lessor">
            <p class="subtitle">WYNAJMUJĄCYM:</p>
            <p class="spaced">Nazwa / osoba: {{ user.first_name }} {{ user.last_name }}</p>
            <p class="spaced">Adres: ul. {{ user.street }} {{ user.building_number }}, {{ user.postal_code }} {{
                        user.living_city }}</p>
            <p class="spaced">NIP / PESEL: {{ user.pesel }}</p>
            <p class="spaced">E-mail: {{ user.email }}</p>
            <p class="spaced">Bank: {{ user.bank_name }}</p>
            <p class="spaced">Nr rachunku: {{ user.bank_account_number }}</p>
        </div>
        <div class="description">
            <h3>§1</h3>
            <p class="spaced">1. Zleceniobiorca oświadcza, że</p>
            <p class="subsection">1.1. nie został skazany prawomocnym wyrokiem za przestępstwa karne skarbowe lub
                przestępstwa umyślne: przeciwko bezpieczeństwu w komunikacji, mieniu, obrotowi
                gospodarczemu, wiarygodności dokumentów, środowisku lub warunkom pracy i płacy
                albo inne mające związek z wykonywaniem zawodu, ani za przestępstwa przeciwko życiu
                i zdrowiu oraz przeciwko wolności seksualnej i obyczajności, a ponadto nie wydano
                prawomocnego orzeczenia zakazującego prowadzenia pojazdów lub zakazującego
                wykonywania zawodu kierowcy.</p>
            <p class="subsection">1.2. posiada prawo jazdy kat. B., jeżeli usługi, o których mowa w § 2 ust. 1,
                będą̨ wykonywane
                z wykorzystaniem samochodu</p>
            <p class="subsection">1.3. nie ma przeciwskazań́ zdrowotnych do świadczenia usług w charakterze
                kierowcy.</p>
            <p class="subsection">1.4. nie ma przeciwskazań́ psychologicznych do świadczenia usług w charakterze
                kierowcy.</p>
            <p class="spaced">2. Zleceniobiorca jest zobowiązany dostarczyć Zleceniodawcy niezwłocznie, ale nie
                później niż
                w ciągu 3 dni od daty podpisania umowy, wszelkie informacje i dokumenty potwierdzające
                jego zdolność do świadczenia usług, w szczególności prawo jazdy (jeśli usługi wymagają korzystania z
                samochodu), zaświadczenia lekarskie oraz inne dokumenty wymagane zgodnie
                z § 1 ust. 1.</p>
            <p class="spaced page-break">3. W sytuacji, gdy następuje modyfikacja danych lub informacji określonych w § 1 ust.
                1,
                Zleceniobiorca zobowiązuje się powiadomić Zleceniodawcę i dostarczyć aktualne dokumenty.
                Każda taka zmiana wraz z odpowiednią dokumentacją powinna być niezwłocznie przekazana
                Zleceniodawcy, nie później jednak niż w ciągu 3 dni od momentu jej wystąpienia.</p>
            <p class="spaced">4. Zleceniobiorca ponosi wszelką odpowiedzialność́, w szczególności cywilną i
                karną,
                za prawdziwość́ dokumentów i informacji przekazywanych Zleceniodawcy, w szczególności
                odpowiada za ich prawidłowość́ oraz zgodność́ ze stanem faktycznym i prawnym.
                Zleceniodawca będzie uprawniony do obciążenia Zleceniobiorcy wszelkimi karami, w tym
                należnościami karnymi lub publicznoprawnymi, którymi w związku z nieprawidłowym
                działaniem lub zaniechaniem Zleceniobiorcy zostanie obciążony Zleceniodawca.</p>
            <p class="spaced">5. Integralną częścią̨ niniejszej Umowy są̨ zapisy Regulaminu Serwisu, które
                znajdują̨
                bezpośrednie zastosowanie do praw i obowiązków Stron. Jednocześnie Zleceniobiorca
                oświadcza, że Regulamin Serwisu został mu udostępniony w sposób pozwalający na
                zapoznanie się z jego treścią oraz oświadcza, że zobowiązuje się̨ przestrzegać́ jego
                postanowień́ i wyraża zgodę na ich stosowanie względem jego osoby.</p>
            <p class="spaced">6. Ponadto, za każde naruszenie lub niewykonanie przez Zleceniobiorcę obowiązków, o
                których
                mowa w § 1 ust. 2-4, Zleceniobiorca zapłaci na rzecz Zleceniodawcy karę umowną w
                wysokości zgodnej z Cennikiem uwidocznionym w Regulaminie. Dodatkowo, Zleceniodawca
                uprawniony będzie do natychmiastowego rozwiązania umowy zawartej ze Zleceniobiorcą.
                Jednocześnie powyższe nie wyklucza dochodzenia przez Zleceniodawcę odszkodowania do
                pełnej wysokości szkody.</p>
            <p class="spaced">7. W zależności od charakteru i rodzaju świadczonych usług, Zleceniodawca może
                udostępnić́
                niezbędną dokumentację lub urządzenia pomocne przy świadczeniu usług.</p>
            <p class="spaced">8. Dokumentacja lub urządzenia, o których mowa w § 1 ust. 7, zostaną wydane
                Zleceniobiorcy
                na podstawie pisemnego protokołu.</p>
            <p class="spaced">9. Zleceniobiorca oświadcza, iż̇ przed podpisaniem niniejszej umowy, zapoznał się z
                Regulaminem Serwisu (dalej: Regulamin), udostępnionym przez Zleceniodawcę za
                pośrednictwem platformy, zaakceptował jego treść i zobowiązuje się̨ do bezwzględnego
                przestrzegania Regulaminu.</p>
            <h3>§2</h3>
            <p class="spaced">1. Zleceniodawca zleca a Zleceniobiorca zobowiązuje się do wykonywania osobiście zleceń́
                przewozu osób lub mienia lub dowozu posiłków/zakupów/towarów na podstawie zleceń́
                od Partnerów Zleceniodawcy (dalej: Partnerzy), o których mowa w Regulaminie,
                pozyskiwanych przez Zleceniobiorcę̨ za pośrednictwem aplikacji mobilnych Partnerów (dalej:
                Aplikacje), wybranych przez Zleceniobiorcę̨. Zakres usług uzależniony jest od wybranej przez
                Zleceniobiorcę̨ Aplikacji. Zleceniodawca nie odpowiada za zakres świadczonych przez
                Zleceniobiorcę̨ usług, jak również̇ za częstotliwość i liczbę zleceń.</p>
            <p class="spaced">2. Jedynie Zleceniobiorca może używać konta założonego na platformie, o której
                mowa w § 1ust 9. Zabronione jest udostępnianie dostępu do profilu Zleceniobiorcy
                jakimkolwiek innym osobom lub podmiotom.</p>
            <p class="spaced">3. Zleceniobiorca zobowiązany jest do wykonywania usług, o których mowa w § 2 ust. 1, w
                sposób zgodny z wszelkimi obowiązującymi przepisami prawa oraz z należytą starannością̨.
                Zleceniobiorca w zależności od rodzaju świadczonych usług zobligowany jest posiadać
                wszelkie wymagane przepisami uprawnienia do ich świadczenia. Ponadto Zleceniobiorca w
                trakcie wykonywania niniejszej umowy zobowiązany jest do dbania o dobro i bezpieczeństwo
                przewożonych pasażerów oraz jakość przewożonego mienia oraz przewożonych
                posiłków/zakupów/towarów, a także zobowiązany jest do działania w szeroko pojętym
                interesie Zleceniodawcy. Jednocześnie Zleceniobiorca zobowiązany jest zapoznać́ się z
                wymogami i wytycznymi wybranych przez siebie Aplikacji i Partnerów dostarczających te
                Aplikacje oraz zobowiązuje się̨ stosować́ się do nich.</p>
            <p class="spaced">4. Zleceniobiorca ponosi wszelką odpowiedzialność́, w tym cywilną oraz karną za wszelkie
                naruszenia, których dopuścił się w toku wykonywania niniejszej umowy, w szczególności
                za wszelkie naruszenia, zaniechania oraz szkody powstałe w trakcie wykonywania usług.
                Zleceniodawca będzie uprawniony do obciążenia Zleceniobiorcy wszelkimi karami, w tym
                należnościami karnymi lub publicznoprawnymi, którymi w związku z nieprawidłowym
                działaniem lub zaniechaniem Zleceniobiorcy zostanie obciążony Zleceniodawca.</p>
            <p class="spaced">5. Zakazane jest wykonywanie przez Zleceniobiorcę usług, o których mowa w § 2 ust. 1, pod
                wpływem alkoholu lub jakichkolwiek innych środków odurzających, pod rygorem nałożenia
                przez Zleceniodawcę̨ na Zleceniobiorcę kary umownej zgodnej z Cennikiem zawartym
                w Regulaminie.</p>
            <p class="spaced">6. Zleceniobiorca ma prawo do wyboru poszczególnych zleceń otrzymywanych poprzez
                Aplikację. Zleceniodawca nie ponosi odpowiedzialności za właściwe funkcjonowanie Aplikacji
                ani za dostępność zleceń. Ponadto, Zleceniodawca nie dostarcza sprzętu elektronicznego
                potrzebnego do przyjmowania i obsługi zleceń przez Zleceniobiorcę.</p>
            <p class="spaced">7. Wszelkie zgłoszone przez Zleceniodawcę wady, usterki lub niedociągnięcia w zakresie
                świadczonych usług Zleceniobiorca ma obowiązek usunąć w terminie wskazanym
                każdorazowo przez Zleceniodawcę.</p>
            <p class="spaced page-break">8. Zakazane jest, w związku z wykonywanymi usługami, o których mowa w § 2 ust. 1, pobieranie
                zapłaty w formie gotówkowej od Klientów Partnerów, jeżeli Zleceniobiorca nie posiada kasy
                fiskalnej lub eKasy.</p>
            <h3>§3</h3>
            <p class="spaced">1. Za wykonanie zobowiązań wynikających z niniejszej umowy, Zleceniodawca będzie
                wynagradzał Zleceniobiorcę na podstawie danych zgromadzonych ze wszystkich Aplikacji,
                w których Zleceniobiorca świadczył usługi objęte niniejszą Umową. Kwota wynagrodzenia
                zostanie obliczona z uwzględnieniem stawek przypisanych do Zleceniobiorcy w
                poszczególnych Aplikacjach, po odjęciu prowizji, opłat zgodnie z Cennikiem określonym w
                Regulaminie, oraz wszelkich obowiązkowych danin publicznych.</p>
            <p class="spaced">2. Zleceniodawca nie gwarantuje Zleceniobiorcy minimalnej liczby zleceń w ramach Aplikacji ani
                godzin świadczenia usług.</p>
            <p class="spaced">3. Okresy rozliczeniowe, w których Zleceniobiorcy będzie wypłacane wynagrodzenie są zależne
                od rodzaju Aplikacji, w ramach których Zleceniobiorca realizuje zlecenia. Na potrzeby
                ustalania okresów rozliczeniowych, jako podstawowy okres przyjmuje się tydzień
                rozliczeniowy, za który strony uznają 7 następujących po sobie dni, począwszy od
                poniedziałku do niedzieli.</p>
            <p class="spaced">4. Zleceniobiorca upoważnia nieodwołalnie Zleceniodawcę do dokonywania potracenia z kwoty
                należnego mu wynagrodzenia wszelkich należności należnych Zleceniodawcy. Oświadczenie
                o potraceniu może zostać dokonane w dowolnej formie.</p>
            <p class="spaced">5. W przypadku pobierania przez Zleceniobiorcę opłat za wykonane przejazdy od Klientów
                w formie gotówkowej, równowartość kwoty uzyskanej przez Zleceniobiorcę będzie
                pomniejszała wysokość wynagrodzenia, o którym mowa w § 3 ust. 1.</p>
            <p class="spaced">6. Wynagrodzenie za usługi Zleceniobiorca będzie otrzymywał przelewem na wskazany w tabeli
                wyżej rachunek bankowy o polskim numerze IBAN lub gotówką w kasie Zleceniodawcy
                w terminie 7 dni od dnia zakończenia danego okresu rozliczeniowego.</p>
            <p class="spaced">7. W przypadku gdy Zleceniobiorca nie świadczy usług w danym okresie rozliczeniowym,
                wynagrodzenie za dany okres nie przysługuje.</p>
            <h3>§4</h3>
            <p class="spaced">1. Zleceniobiorca zobowiązany jest niezwłocznie, nie później niż w terminie 3 dni, informować
                Zleceniodawcę o:</p>
            <p class="subsection">1.1. trwałej lub tymczasowej utracie prawa jazdy, jeżeli usługi, o których mowa w § 2 ust. 1,
                będą wykonywane są wykorzystaniem samochodu;</p>
            <p class="subsection">1.2. trwałej lub tymczasowej utracie uprawnień do wykonywania usług świadczonych na rzecz
                Zleceniodawcy;;</p>
            <p class="subsection">1.3. wygaśnięciu umowy ubezpieczenia pojazdu, jeżeli usługi, o których mowa w § 2 ust. 1, będą
                wykonywane z wykorzystaniem samochodu;</p>
            <p class="subsection">1.4. zawarciu nowej umowy ubezpieczenia pojazdu, jeżeli usługi, o których mowa w § 2 ust. 1,
                będą wykonywane z wykorzystaniem samochodu;</p>
            <p class="subsection">1.5. zawarciu nowej umowy ubezpieczenia pojazdu, jeżeli usługi, o których mowa w § 2 ust. 1,
                będą wykonywane z wykorzystaniem samochodu;</p>
            <p class="subsection">1.6. zaistnieniu przeciwwskazań, o których mowa w § 1 ust. 1 pkt 3 i 4.</p>
            <p class="spaced">2. Za każdorazowe naruszenie obowiązków, o których mowa w § 4 ust. 1, Zleceniobiorca
                zobowiązany jest do zapłaty na rzecz Zleceniodawcy kary umownej w kwocie ustalonej
                zgodnie z Cennikiem w Regulaminie.</p>
            <p class="spaced">3. Bez zwłoki, nie później niż w ciągu 7 dni od zakończenia lub rozwiązania umowy, oraz na każde
                żądanie Zleceniodawcy lub Partnera, Zleceniobiorca zobowiązany jest przekazać Zleceniodawcy
                wszelką dokumentację oraz niezbędne urządzenia, które zostały udostępnione Zleceniobiorcy
                przez Zleceniodawcę w celu wykonywania zleceń. Sporządzony zostanie pisemny protokół
                z przekazania.</p>
            <p class="spaced">4. W sytuacji opóźnienia Zleceniobiorcy w przekazaniu dokumentacji i przekazanych
                przedmiotów, Zleceniobiorca będzie zobowiązany do uiszczenia na rzecz Zleceniodawcy kary
                umownej, ustalonej zgodnie z Cennikiem określonym w Regulaminie, za każdy dzień
                opóźnienia.</p>
            <h3>§5</h3>
            <p class="spaced page-break">1. Umowa zostaje zawarta na czas nieokreślony, a dniem wejścia w życie jej postanowień́jest
                dzień́realizacji pierwszej usługi przez Zleceniobiorcę̨ albo dzień́pierwszej rejestracji usługi
                na koncie Zleceniobiorcy w zależności od tego, które ze zdarzeń nastąpi wcześniej.</p>
            <p class="spaced">2. Umowa może zostać wypowiedziana przez każdą ze Stron z zachowaniem okresu
                wypowiedzenia wynoszącego 1 (jeden) tydzień.</p>
            <p class="spaced">3. Umowa może zostać rozwiązana przez dowolną Stronę, natychmiastowo, jeśli druga Strona nie
                wywiązuje się lub wykonuje swoje zobowiązania umowne w sposób nieodpowiedni, zwłaszcza
                w przypadku gdy Zleceniobiorca nie realizuje przydzielonych mu zadań, jak określono w §2.</p>
            <p class="spaced">4. Oświadczenie o wypowiedzeniu Umowy albo jej rozwiązaniu w trybie natychmiastowym może
                zostać złożone w formie:</p>
            <p class="subsection">4.1. pisemnej na adresy wskazane w komparycji niniejszej Umowy bądź</p>
            <p class="subsection">4.2. dokumentowej na następujące adresy email:</p>
            <ul class="subsection">
                <li>Zleceniodawca: <strong>biuro@cabbie.pl</strong></li>
                <li>Zleceniobiorca: <strong>{{ user.email }}</strong></li>
            </ul>
            <p class="spaced">5. Oświadczenie, o którymmowaw ust. 4, uważa się̨za skutecznie złożonez chwilą jego odbioru
                przez adresata, a w braku potwierdzenia tego odbioru:</p>
            <p class="subsection">5.1. w przypadku złożenia oświadczenia w formie pisemnej - po upływie 2 tygodni od pierwszego
                awizo na adresy wskazane w komparycji niniejszej Umowy,</p>
            <p class="subsection">5.2.w przypadku złożenia oświadczenia w formie dokumentowej - po upływie 2 tygodni od daty
                wysłania wiadomości email.</p>
            <h3>§6</h3>
            <p class="spaced">1. Zleceniodawca ma prawo przenieść wszystkie prawa i obowiązki wynikające z Umowy na rzecz
                innego podmiotu. W przypadku takiego przeniesienia, Zleceniodawca zobowiązuje się
                poinformować o tym Zleceniobiorcę w ciągu 7 dni od daty dokonania zmiany, wskazując
                podmiot, na rzecz którego prawa i obowiązki zostały przeniesione. Takie przeniesienie nie
                wpływa na ważność ani skuteczność Umowy, a zgoda Zleceniobiorcy nie jest wymagana.</p>
            <p class="spaced">2. We wszelkich kwestiach nieuregulowanych w Umowie zastosowanie znajdują odpowiednie
                postanowienia Kodeksu cywilnego oraz Regulaminu.</p>
            <p class="spaced">3. Wszelkie spory będą rozstrzygane przez sąd powszechny właściwy wg siedziby Zleceniodawcy.</p>
            <p class="spaced">4. Każda zmiana treści Umowy musi być dokonana na piśmie, w przeciwnym razie będzie
                nieważna, z zastrzeżeniem zmian treści Regulaminu, które wchodzą w życie zgodnie z zasadami
                określonymi w Regulaminie.</p>
            <p class="spaced">5. Umowę sporządzono w dwóch jednobrzmiących egzemplarzach.</p>
        </div>
        <div class="signature-section">
            <table class="signature-table">
                <tr>
                    <td>
                        <p class="signature-title">Zleceniobiorca:</p>
                    </td>
                    <td>
                        <p class="signature-title">Zleceniodawca:</p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="action-button">
        <button class="generate-pdf" @click="generatePDF">POBIERZ PDF</button>
        <button class="autenti-confirmation" @click="signWithAutenti">POTWIERDZAM DANE I PODPISUJĘ AUTENTI (JUŻ WKRÓTCE)</button>
    </div>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import {
    mapGetters,
} from 'vuex';

export default {
    data() {
        return {
            currentDate: ''
        }
    },
    computed: {
        ...mapGetters(['getUser']),
        user() {
            return this.getUser || {};
        },
    },
    methods: {
        generatePDF() {
            const element = this.$refs.content;
            const options = {
                margin: 1,
                filename: 'Umowa_Zlecenie.pdf',
                image: {
                    type: 'jpeg',
                    quality: 0.98
                },
                html2canvas: {
                    scale: 2
                },
                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait'
                }
            };

            html2pdf()
                .from(element)
                .set(options)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages();
                    const title = 'Umowa Zlecenie';

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);

                        if (i === 1) {
                            pdf.setFontSize(14);
                            pdf.setFont("helvetica", "bold");
                            pdf.text(title, pdf.internal.pageSize.getWidth() / 2, 0.5, {
                                align: 'center'
                            });
                        }

                        const x = pdf.internal.pageSize.getWidth() / 2;
                        const y = pdf.internal.pageSize.getHeight() - 0.5;
                        pdf.setFontSize(8);
                        pdf.setFont("helvetica", "normal");
                        pdf.text(`${i} z ${totalPages}`, x, y, {
                            align: 'center'
                        });
                    }
                })
                .save();
        },
        setCurrentDate() {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            this.currentDate = `${day}.${month}.${year} r.`;
        },
        goBack() {
            this.$router.go(-1);
        }
    },
    mounted() {
        this.setCurrentDate();
    }
}
</script>

    
<style lang="scss" scoped>
.page-break {
    page-break-after: always;
}

.umowa-zlecenie {
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
    margin: 100px;
}

.umowa-content {
    text-align: justify;
    font-family: Arial, sans-serif;
}

.title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.subtitle {
    font-size: 11px;
    font-weight: bold;
}

.lessee,
.lessor {
    font-size: 11px;
}

.signature-section {
    font-size: 11px;
    text-align: center;
}

.signature-table {
    width: 100%;
    border-collapse: collapse;
}

.signature-table td {
    width: 50%;
    padding: 20px;
    vertical-align: bottom;
    text-align: center;
}

.signature-title {
    font-weight: bold;
    font-size: 11px;
}

.company-info {
    text-align: left;
    font-weight: bold;
    font-size: 11px;
    line-height: 0.1;
}

.contract-details {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 11px;
    line-height: 0.1;
}

.description {
    margin-left: 20px;
    font-size: 11px;
}

h3 {
    text-align: center;
}

.spaced {
    margin-bottom: 0.4em;
}

.subsection {
    margin-left: 30px;
}

.action-button {
    display: flex;
    gap: 10px;
}

.generate-pdf,
.autenti-confirmation {
    padding: 10px 15px;
    background-color: $primary-color;
    color: $tertiary-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.generate-pdf:hover,
.autenti-confirmation:hover {
    background-color: $text-container-color;
    color: $primary-color;
}

.back-button {
    position: absolute;
    background-color: transparent;
    border: none;
    color: $primary-color;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.back-button i {
    margin-right: 5px;
}

.back-button:hover {
    color: $secondary-color;
}

@media (max-width: 768px) {
    .title {
        margin-top: 100px;
    }

    .umowa-zlecenie {
        margin: 30px;
    }

    .company-info,
    .contract-details {
        line-height: 0.2;
    }

    .description {
        margin-left: 0;
    }
}
</style>