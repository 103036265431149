<template>
  <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div v-if="loading" class="loading-modal">
      <LoaderComponent class="loading" />
    </div>
  </transition>

  <div class="applications">
    <p class="title">APLIKACJE</p>
    <p class="section-description">
      Zostań kierowcą w jednej z wiodących platform transportowych! Nasza firma pomoże Ci założyć konto na Bolt, Uber
      lub FreeNow, oferując pełne wsparcie i elastyczne warunki pracy.
    </p>
    <p class="info-text">
      Oferujemy Ci dostęp do popularnych aplikacji transportowych. Wybierz platformę, która najlepiej odpowiada Twoim
      potrzebom, i zacznij zarabiać w dogodnych dla Ciebie godzinach. Jako firma zapewniamy pełne wsparcie przy
      zakładaniu konta oraz pomoc na każdym etapie współpracy.
    </p>
    <div class="cards">
      <div :class="{ 'card-disabled': userSurveyData && (userSurveyData.city !== 'Białystok' && userSurveyData.city !== 'Biała Podlaska' && userSurveyData.city !== 'Suwałki') }" class="card card-bolt">
        <div class="card-content">
          <div v-if="user.bolt_driver && user.bolt_driver_id">
            <p class="app-description">Aplikacja aktywna!</p>
            <button class="btn" @click="unsubscribeBolt">Wypisz się</button>
          </div>
          <div v-else-if="user.bolt_driver && !user.bolt_driver_id">
            <p class="app-description">W trakcie realizacji. Prosimy o cierpliwość.</p>
          </div>
          <div v-else>
            <p class="app-description">Dołącz jako kierowca Bolt!</p>
            <button class="btn" @click="signUpBolt">Zapisz się</button>
          </div>
        </div>
      </div>

      <div :class="{ 'card-disabled': userSurveyData && userSurveyData.city !== 'Białystok' }" class="card card-uber">
        <div class="card-content">
          <div v-if="user.uber_driver && user.uber_driver_id">
            <p class="app-description">Aplikacja aktywna!</p>
            <button class="btn" @click="unsubscribeUber">Wypisz się</button>
          </div>
          <div v-else-if="user.uber_driver && !user.uber_driver_id">
            <p class="app-description">W trakcie realizacji. Prosimy o cierpliwosć.</p>
          </div>
          <div v-else>
            <p class="app-description">Dołącz jako kierowca Uber!</p>
            <button class="btn" @click="signUpUber">Zapisz się</button>
          </div>
        </div>
        <div v-if="userSurveyData && (userSurveyData.city === 'Biała Podlaska' || userSurveyData.city === 'Suwałki')"
          class="overlay">
          <p class="overlay-text">Niedostępne w Twoim mieście</p>
        </div>
      </div>

      <div :class="{ 'card-disabled': userSurveyData && userSurveyData.city !== 'Białystok' }"
        class="card card-freenow">
        <div class="card-content">
          <div v-if="user.freenow_driver && user.freenow_driver_id">
            <p class="app-description">Aplikacja aktywna!</p>
            <button class="btn" @click="unsubscribeFreenow">Wypisz się</button>
          </div>
          <div v-else-if="user.freenow_driver && !user.freenow_driver_id">
            <p class="app-description">W trakcie realizacji. Prosimy o cierpliwosć.</p>
          </div>
          <div v-else>
            <p class="app-description">Dołącz jako kierowca FreeNow!</p>
            <button class="btn" @click="signUpFreenow">Zapisz się</button>
          </div>
        </div>
        <div v-if="userSurveyData && (userSurveyData.city === 'Biała Podlaska' || userSurveyData.city === 'Suwałki')"
          class="overlay">
          <p class="overlay-text">Niedostępne w Twoim mieście</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import apiService from '@/apiService';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';

export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    LoaderComponent
  },
  computed: {
    ...mapGetters(['getUserSurveyData', 'getUser']),
    user() {
      return this.getUser || {};
    },
    userSurveyData() {
      return this.getUserSurveyData;
    },
  },
  methods: {
    async signUpBolt() {
      try {
        await this.sendApplication('Bolt');
      } catch (error) {
        this.showError('Wystąpił błąd podczas zapisywania się na platformę Bolt. Spróbuj ponownie.');
      }
    },
    async signUpUber() {
      try {
        await this.sendApplication('Uber');
      } catch (error) {
        this.showError('Wystąpił błąd podczas zapisywania się na platformę Uber. Spróbuj ponownie.');
      }
    },
    async signUpFreenow() {
      try {
        await this.sendApplication('FreeNow');
      } catch (error) {
        this.showError('Wystąpił błąd podczas zapisywania się na platformę FreeNow. Spróbuj ponownie.');
      }
    },
    async sendApplication(platform) {
      const { first_name, last_name, email } = this.user;
      const data = {
        first_name,
        last_name,
        email,
        applications: platform
      };

      this.loading = true;

      try {
        const response = await apiService.post('/notify-employee', data);

        if (response.data && response.data.message === 'Mail został wysłany') {
          this.showSuccess(`Twoja aplikacja na platformę ${platform} została pomyślnie przesłana. Skontaktujemy się z Tobą, gdy wszystko będzie gotowe!`);
        } else {
          this.showError(`Nie udało się przesłać aplikacji na platformę ${platform}. Spróbuj ponownie.`);
        }
      } catch (error) {
        this.showError('Wystąpił problem z połączeniem. Spróbuj ponownie.');
      } finally {
        this.loading = false;
      }
    },
    showSuccess(message) {
      Swal.fire({
        icon: 'success',
        title: 'Aplikacja przesłana!',
        text: message
      });
    },
    showError(message) {
      Swal.fire({
        icon: 'error',
        title: 'Błąd!',
        text: message
      });
    },
    unsubscribeBolt() {
      this.unsubscribe('Bolt');
    },
    unsubscribeUber() {
      this.unsubscribe('Uber');
    },
    unsubscribeFreenow() {
      this.unsubscribe('FreeNow');
    },
    async unsubscribe(platform) {
      const { first_name, last_name, email } = this.user;
      const data = {
        first_name,
        last_name,
        email,
        applications: platform
      };

      this.loading = true;

      try {
        const response = await apiService.post('/notify-employee-about-remove', data);

        if (response.data && response.data.message === 'Mail został wysłany') {
          this.showSuccess(`Twoja rezygnacja z platformy ${platform} została pomyślnie przesłana. Skontaktujemy się z Tobą, gdy wszystko będzie gotowe!`);
        } else {
          this.showError(`Nie udało się wypisać z platformy ${platform}. Spróbuj ponownie.`);
        }
      } catch (error) {
        this.showError('Wystąpił problem z połączeniem. Spróbuj ponownie.');
      } finally {
        this.loading = false;
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight;
      el.style.transition = 'opacity 0.6s';
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 0;
      done();
    },
  }
}
</script>

<style lang="scss" scoped>
.applications {
  margin: 0 auto;
  padding: 20px;
  font-size: 24px;
  color: $white;
}

.title {
  text-align: left;
  font-family: 'Roboto-Light', sans-serif;
  color: $white;

  &:after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: $primary-color;
    margin-top: 5px;
    margin-left: 2px;
  }
}

.section-description,
.info-text {
  font-size: 18px;
  color: $white;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-family: 'Roboto-Light', sans-serif;
  text-align: justify;
}

.cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.card {
  width: 300px;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  font-family: 'Roboto-Light', 'sans-serif';
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.card-content {
  opacity: 0.95;
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  background: $remind-password;
  color: $white;
  padding: 20px;
  border-radius: 15px;
  transition: all 0.3s ease;

  .app-description {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .btn {
    background-color: $secondary-color;
    color: $primary-color;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
      color: $tertiary-color;
    }
  }
}

.card:not(.card-disabled):hover .card-content {
  bottom: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid transparent;
}

.card-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.overlay-text {
  text-align: center;
  font-size: 16px;
  color: $title-light-font;
}

.card-bolt {
  background-image: url('@/assets/images/bolt-logo.jpg');
  background-position: center 25%;
}

.card-uber {
  background-image: url('@/assets/images/uber-logo.jpg');
}

.card-freenow {
  background-image: url('@/assets/images/freenow-logo.jpeg');
}

.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

@media (max-width: 768px) {
  .cards {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .card {
    width: 100%;
  }
}
</style>