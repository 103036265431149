<template>
    <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="loading" class="loading-modal">
            <LoaderComponent class="loading" />
        </div>
    </transition>

    <div class="documents">
        <p class="title">DOKUMENTY</p>
        <div class="checkbox-section" :class="{ 'disabled': user.has_license_documents }">
            <label class="input-label" v-if="!user.has_license_documents">
                <input type="checkbox" :checked="localHasLicenseDocuments" @change="handleCheckboxChange" />
                <span></span>
                Zaznacz jeśli zebrałeś komplet wymaganych
                dokumentów do procesu licencjonowania w Twoim mieście
            </label>
            <p class="info" v-else>Posiadam komplet dokumentów do licencjonowania.</p>
        </div>

        <div class="to-download">
            <p class="subtitle">Do pobrania</p>
            <ul class="document-list">
                <li v-if="!isFormVerified">
                    <a href="https://cloud.cabbie.pl/index.php/s/XPH5oJFgq88pMsd/download">Klauzula informacyjna</a>
                </li>
                <li v-if="!isFormVerified">
                    <a href="https://cloud.cabbie.pl/index.php/s/95b3LLGq5aHkfJX/download">Zgoda na przetwarzanie
                        danych</a>
                </li>
                <li v-if="!isFormVerified">
                    <a href="https://cloud.cabbie.pl/index.php/s/cn9DwnMjHDTyC8F/download">Deklaracja o rezygnacji z
                        PPK</a>
                </li>
                <li v-if="!isFormVerified">
                    <a href="https://cloud.cabbie.pl/index.php/s/4QKXzAoF2EsA9oR/download">Kwestionariusz
                        zleceniobiorcy</a>
                </li>
                <li v-if="isFormVerified">
                    <router-link to="/umowa-zlecenie">Umowa Zlecenie</router-link>
                </li>
                <li v-if="isFormVerified">
                    <router-link to="/umowa-najmu">Umowa Najmu</router-link>
                </li>
            </ul>
        </div>
        <div class="to-upload">
            <p class="subtitle">Do wgrania</p>
            <p v-if="!isFormVerified" class="info">
                Wydrukuj, uzupełnij wszystkie dane i podpisz. Wgraj skan dokumentu tutaj. Po zweryfikowaniu przesłanych
                dokumentów otrzymasz wiadomość e-mail. Prosimy o cierpliwość.
            </p>
            <p v-if="isFormVerified" class="info">Wydrukuj i podpisz za pośrednictwem koordynatora (wkrótce również
                AUTENTI)</p>
            <p class="info" v-if="isFormVerified && userSurveyData">
                <strong>KONTAKT DO KOORDYNATORA: </strong>
                <span v-if="userSurveyData.city === 'Białystok'">+48 500 061 435 - Kamil</span>
                <span v-else-if="userSurveyData.city === 'Suwałki'">+48 693 877 188 - Karol</span>
                <span v-else-if="userSurveyData.city === 'Biała Podlaska'">+48 509 860 850 - Kacper</span>
                <span v-else>Nr kontaktowy niebawem</span>
            </p>
            <label for="file" class="custum-file-upload">
                <div class="icon">
                    <svg class="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" />
                    </svg>
                </div>
                <div class="text">
                    <span v-if="!fileName">Kliknij, aby wgrać plik</span>
                    <span v-else>{{ fileName }}</span>
                </div>
                <input id="file" type="file" @change="handleFileUpload" ref="fileInput" />
            </label>
            <button v-if="fileName" @click="submitFile" class="upload-button">Wyślij</button>
            <button v-if="fileName" @click="resetFile" class="reset-button">Cofnij</button>
        </div>
    </div>
</template>

<script>
import LoaderComponent from '@/components/Common/LoaderComponent.vue';
import apiService from '@/apiService';
import Swal from 'sweetalert2';
import { mapGetters, mapActions } from 'vuex';
import jsPDF from 'jspdf';

export default {
    components: {
        LoaderComponent
    },
    data() {
        return {
            fileName: null,
            convertedPdf: null,
            loading: false,
            localHasLicenseDocuments: null,
        };
    },
    created() {
        this.localHasLicenseDocuments = this.user.has_license_documents;
    },
    computed: {
        ...mapGetters(['isFormVerified', 'getUserSurveyData', 'getUser']),
        user() {
            return this.getUser || {};
        },
        userSurveyData() {
            return this.getUserSurveyData;
        },
    },
    methods: {
        ...mapActions(['updateUser']),
        handleCheckboxChange(event) {
            const newValue = event.target.checked;

            Swal.fire({
                title: 'Czy na pewno chcesz zmienić status?',
                text: "Ta akcja zaktualizuje dane użytkownika.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Tak, zmień!',
                cancelButtonText: 'Anuluj',
            }).then((result) => {
                if (result.isConfirmed) {
                    const updatedData = {
                        has_license_documents: newValue,
                    };

                    this.updateUser(updatedData).then(() => {
                        this.localHasLicenseDocuments = newValue;
                        this.user.has_license_documents = newValue;

                        const message = newValue
                            ? 'Informacja została przekazana!'
                            : 'Informacja nie została przekazana!';

                        Swal.fire({
                            title: newValue ? 'Sukces!' : 'Błąd!',
                            text: message,
                            icon: newValue ? 'success' : 'error',
                            confirmButtonText: 'OK',
                        });
                    }).catch(error => {
                        Swal.fire({
                            title: 'Błąd!',
                            text: error.message || 'Wystąpił problem podczas aktualizacji danych.',
                            icon: 'error',
                            confirmButtonText: 'OK',
                        });
                    });
                } else {
                    this.localHasLicenseDocuments = this.user.has_license_documents; // Przywrócenie stanu
                }
            });
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.fileName = file.name;

                if (file.type.startsWith('image/')) {
                    this.convertImageToPdf(file);
                }
            }
        },
        async convertImageToPdf(file) {
            const pdf = new jsPDF();
            const imageUrl = URL.createObjectURL(file);

            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;

                    const pageWidth = pdf.internal.pageSize.width;
                    const pageHeight = pdf.internal.pageSize.height;

                    const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
                    const newWidth = imgWidth * ratio;
                    const newHeight = imgHeight * ratio;

                    pdf.addImage(img, 'JPEG', 0, 0, newWidth, newHeight);

                    const pdfOutput = pdf.output('blob');
                    this.convertedPdf = pdfOutput;

                    Swal.fire({
                        icon: 'success',
                        title: 'Konwersja zakończona',
                        text: 'Obraz został przekonwertowany na PDF.',
                    });

                    resolve();
                };

                img.onerror = reject;
                img.src = imageUrl;
            });
        },

        async submitFile() {
            const formData = new FormData();
            const fileInput = this.$refs.fileInput;

            if (!fileInput.files.length) {
                Swal.fire({
                    icon: 'error',
                    title: 'Błąd',
                    text: 'Wybierz plik, aby go wysłać.',
                });
                return;
            }

            const file = fileInput.files[0];
            const firstName = this.$store.state.user.first_name;
            const lastName = this.$store.state.user.last_name;

            formData.append('first_name', firstName);
            formData.append('last_name', lastName);

            if (this.convertedPdf) {
                const pdfFileName = this.fileName.replace(/\.[^/.]+$/, '.pdf');
                formData.append('file', this.convertedPdf, pdfFileName);
            } else {
                formData.append('file', file);
            }

            this.uploadFileToServer(formData);
        },

        async uploadFileToServer(formData) {
            this.loading = true;

            try {
                let endpoint;
                if (this.isFormVerified) {
                    endpoint = '/driver/upload/umowy';
                } else {
                    endpoint = '/driver/upload/kwestionariusze';
                }

                const response = await apiService.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Sukces',
                        text: 'Plik został wysłany pomyślnie',
                    });
                    this.resetFile();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Błąd',
                        text: 'Błąd podczas wysyłania pliku.',
                    });
                }
            } catch (error) {
                console.error('Błąd podczas wysyłania pliku:', error);

                let errorMessage = 'Nie udało się wysłać pliku.';
                if (error.response && error.response.data) {
                    errorMessage = error.response.data.message || JSON.stringify(error.response.data);
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Błąd',
                    text: errorMessage,
                });
            } finally {
                this.loading = false;
            }
        },

        resetFile() {
            this.fileName = null;
            this.$refs.fileInput.value = "";
            this.convertedPdf = null;
        },
        beforeEnter(el) {
            el.style.opacity = 0;
        },
        enter(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.6s';
            el.style.opacity = 1;
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 0;
            done();
        },
    },
};
</script>

<style lang="scss" scoped>
.documents {
    margin: 0 auto;
    padding: 20px;
    color: $white;
    display: flex;
    font-family: 'Roboto-Light', sans-serif;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    .title {
        font-size: 24px;
        text-align: left;
        color: $white;
        margin-bottom: 15px;
        position: relative;

        &:after {
            content: '';
            display: block;
            width: 50px;
            height: 3px;
            background: $primary-color;
            margin-top: 5px;
            margin-left: 2px;
        }
    }

    .subtitle {
        margin: 20px;
        color: $white;
        font-size: 20px;
    }

    .info {
        margin: 20px;
        color: $white;
        font-size: 14px;
    }

    .to-download,
    .to-upload,
    .checkbox-section {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-bottom: 20px;

        .document-list {
            list-style: none;
            padding: 0;
            margin: 20px;

            li {
                margin: 10px 0;

                a,
                .router-link {
                    display: inline-block;
                    text-decoration: none;
                    color: $tertiary-color;
                    font-size: 16px;
                    padding: 10px 15px;
                    border-radius: 8px;
                    transition: all 0.3s ease;
                    font-weight: bold;
                    background: $primary-color;
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

                    &:hover {
                        background: $tertiary-color;
                        color: $primary-color;
                        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }
    }

    .custum-file-upload {
        font-family: 'Roboto-Light', sans-serif;
        margin: 20px;
        height: 50px;
        width: 200px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: $tertiary-color;
        padding: 10px;
        border-radius: 10px;
        transition: all 0.3s ease;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;

            .svg-icon {
                height: 25px;
                fill: $white;
                transition: fill 0.3s ease;
            }
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: $white;
            transition: all 0.3s ease;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        input {
            display: none;
        }
    }

    .custum-file-upload:hover {
        background-color: $primary-color;
    }

    .custum-file-upload:hover .svg-icon {
        fill: $tertiary-color;
    }

    .custum-file-upload:hover .text {
        color: $tertiary-color;
    }
}

.upload-button {
    margin: 0px 0px 20px 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: $white;
    background-color: $primary-color;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: $tertiary-color;
        color: $primary-color;
    }
}

.reset-button {
    margin: 0px 0px 20px 10px;
    padding: 10px 20px;
    font-size: 16px;
    color: $white;
    background-color: $text-container-color;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: $remind-password;
    }
}

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.input-label {
    display: flex;
    align-items: flex-start;
    margin: 20px;
    color: $primary-color;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 16px;
    cursor: pointer;

    input {
        display: none;
    }

    span {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid $primary-color;
        border-radius: 4px;
        margin-right: 10px;
        position: relative;
        background-color: $tertiary-color;
        transition: background-color 0.3s ease, border-color 0.3s ease;
    }

    input:checked+span {
        background-color: $primary-color;
        border-color: $primary-color;
    }

    input:checked+span::after {
        content: "";
        position: absolute;
        top: 40%;
        left: 50%;
        width: 10px;
        height: 6px;
        border: solid $tertiary-color;
        border-width: 0 0 2px 2px;
        transform: translate(-50%, -40%) rotate(-45deg);
    }

    span:hover {
        background-color: lighten($primary-color, 20%);
        border-color: $primary-color;
    }

    input:focus+span {
        outline: 2px solid $primary-color;
        outline-offset: 4px;
    }
}

.disabled {
    pointer-events: none;
    opacity: 1;
}

@media (max-width: 768px) {
    .input-label {
        flex-direction: column;
        gap: 10px;
    }
}
</style>