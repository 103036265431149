import axios from 'axios';
import store from '@/store';
async function fetchCsrfToken() {
    try {
        const response = await axios.get('https://laravel.cabbie.pl/csrf-token', { withCredentials: true });
        const csrfToken = response.data.csrfToken;
        sessionStorage.setItem('csrfToken', csrfToken);
        return csrfToken;
    } catch (error) {
        console.error('Error fetching CSRF token:', error);
        throw error;
    }
}

async function getCsrfToken() {
    let csrfToken = sessionStorage.getItem('csrfToken');
    if (!csrfToken) {
        csrfToken = await fetchCsrfToken();
    }
    return csrfToken;
}

const apiService = axios.create({
    baseURL: 'https://laravel.cabbie.pl/',
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

apiService.interceptors.request.use(async (config) => {
    try {
        const csrfToken = await getCsrfToken();
        if (csrfToken) {
            config.headers['X-CSRF-TOKEN'] = csrfToken;
        }
        return config;
    } catch (error) {
        return Promise.reject(error);
    }
}, (error) => {
    return Promise.reject(error);
});

apiService.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 419) {
            try {
                const csrfToken = await fetchCsrfToken();
                if (csrfToken) {
                    error.config.headers['X-CSRF-TOKEN'] = csrfToken;
                    return apiService.request(error.config);
                }
            } catch (tokenError) {
                console.error('Failed to refresh CSRF token. Logging out:', tokenError);
                store.dispatch('logout');
                return Promise.reject(tokenError);
            }
        }
        return Promise.reject(error);
    }
);

export default apiService;