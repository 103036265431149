<template>
  <main>
    <section id="welcome">
      <WelcomePageSection />
    </section>
    <section id="offer">
      <OfferSection />
    </section>
    <section id="history">
      <CabbiePanelSection />
    </section>
    <section id="check-offer">
      <CheckOfferSection />
    </section>
    <section id="lets-begin">
      <LetsBeginSection />
    </section>
    <section id="opinions" ref="opinionsSection" :class="{ 'is-visible': isVisible }"> <!-- przeniesiono tutaj bo w komponencie cos nie dziala xd -->
      <OpinionsSection />
    </section>
    <section id="contact">
      <ContactForm />
    </section>
  </main>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import WelcomePageSection from './WelcomePageSection.vue';
import OfferSection from './OfferSection.vue';
import CabbiePanelSection from './CabbiePanelSection.vue';
import CheckOfferSection from './CheckOfferSection.vue';
import LetsBeginSection from './LetsBeginSection.vue';
import OpinionsSection from './OpinionsSection.vue';
import ContactForm from '@/components/Views/HomePage/ContactForm.vue';

const isVisible = ref(false);
const opinionsSection = ref(null);

const handleIntersection = (entries) => {
  if (entries[0].isIntersecting) {
    isVisible.value = true;
  }
};

let observer;

onMounted(() => {
  observer = new IntersectionObserver(handleIntersection, {
    rootMargin: "30px 0px",
    threshold: 0.2,
  });
  if (opinionsSection.value) {
    observer.observe(opinionsSection.value);
  }
});

onUnmounted(() => {
  if (observer && opinionsSection.value) {
    observer.unobserve(opinionsSection.value);
  }
});
</script>

<style scoped>
section {
  width: 100%;
  margin: 0;
}

section#opinions {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

section#opinions.is-visible {
  opacity: 1;
  transform: translateY(0);
}
</style>