<template>
    <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="loading" class="loading-modal">
            <LoaderComponent class="loading" />
        </div>
    </transition>

    <div>
        <p class="title">LOGOWANIE</p>
        <form @submit.prevent="login">
            <div class="input-group">
                <input class="input" type="email" id="email" placeholder="E-mail" v-model="email" required />
            </div>
            <div class="input-group">
                <input class="input" type="password" id="password" placeholder="Hasło" v-model="password" required />
            </div>
            <div class="remind-password">
                <button class="remind-password-button" type="button" @click="openRemindPasswordForm">
                    Nie pamiętam hasła
                </button>
            </div>
            <div class="buttons-group">
                <button class="button" type="submit">ZALOGUJ</button>
            </div>
        </form>

        <transition name="modal" @before-enter="beforeEnterOverlay" @enter="enterOverlay" @leave="leaveOverlay">
            <div v-if="showVerification" class="modal-backdrop" @click.self="closeVerification">
                <transition name="modal-content" @before-enter="beforeEnterModal" @enter="enterModal"
                    @leave="leaveModal">
                    <div class="modal">
                        <AdminVerification @close-verification="closeVerification" />
                    </div>
                </transition>
            </div>
        </transition>

        <transition name="modal" @before-enter="beforeEnterOverlay" @enter="enterOverlay" @leave="leaveOverlay">
            <div v-if="showRemindPassword" class="modal-backdrop" @click.self="closeRemindPassword">
                <transition name="modal-content" @before-enter="beforeEnterModal" @enter="enterModal"
                    @leave="leaveModal">
                    <div class="modal">
                        <RemindPasswordForm @close-remind="closeRemindPassword" />
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import AdminVerification from './AdminVerification.vue';
import RemindPasswordForm from './RemindPasswordForm.vue';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';

export default {
    components: {
        AdminVerification,
        RemindPasswordForm,
        LoaderComponent
    },
    data() {
        return {
            email: '',
            password: '',
            showVerification: false,
            showRemindPassword: false,
            loading: false,
        };
    },
    mounted() {
        const verificationRequired = sessionStorage.getItem('verificationRequired') === 'true';
        if (verificationRequired) {
            this.$store.dispatch('logout');
            sessionStorage.removeItem('verificationRequired');
        }
    },
    methods: {
        async login() {
            this.loading = true;
            try {
                const response = await this.$store.dispatch('login', {
                    email: this.email,
                    password: this.password,
                });

                if (response && response.message === 'verification_required') {
                    this.showVerification = true;
                    sessionStorage.setItem('verificationRequired', 'true');
                    return;
                }

                const firstLogin = this.$store.getters.getFirstLogin;

                if (firstLogin) {
                    await Swal.fire({
                        title: 'Witamy!',
                        text: 'Zalogowano pomyślnie. Zostaniesz przekierowany do formularza początkowego.',
                        icon: 'info',
                        confirmButtonText: 'OK',
                    });
                    this.$emit('login');
                    this.$router.push({
                        name: 'Survey'
                    });
                } else {
                    await Swal.fire({
                        title: 'Sukces!',
                        text: 'Logowanie przebiegło pomyślnie.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    });
                    this.$emit('login');
                    this.$router.push({
                        name: 'Home'
                    });
                }
            } catch (error) {
                let errorMessage;

                if (error.response && error.response.data && error.response.data.error) {
                    errorMessage = error.response.data.error;
                } else if (error.message) {
                    errorMessage = error.message;
                } else {
                    errorMessage = 'Nieznany błąd serwera.';
                }

                await Swal.fire({
                    title: 'Błąd!',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                this.loading = false;
            }
        },
        openRemindPasswordForm() {
            this.showRemindPassword = true;
        },
        closeRemindPassword() {
            this.showRemindPassword = false;
        },
        closeVerification() {
            this.showVerification = false;
            sessionStorage.removeItem('verificationRequired');
            this.$emit('login');
        },
        beforeEnterOverlay(el) {
            el.style.opacity = 0;
            el.style.visibility = 'visible';
        },
        enterOverlay(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.3s ease';
            el.style.opacity = 1;
            done();
        },
        leaveOverlay(el, done) {
            el.style.transition = 'opacity 0.3s ease';
            el.style.opacity = 0;
            setTimeout(done, 300);
        },
        beforeEnterModal(el) {
            el.style.transform = 'scale(0.7)';
            el.style.opacity = 0;
        },
        enterModal(el, done) {
            el.offsetHeight;
            el.style.transition = 'transform 0.3s ease, opacity 0.3s ease';
            el.style.transform = 'scale(1)';
            el.style.opacity = 1;
            done();
        },
        leaveModal(el, done) {
            el.style.transition = 'transform 0.3s ease, opacity 0.3s ease';
            el.style.transform = 'scale(0.7)';
            el.style.opacity = 0;
            setTimeout(done, 300);
        },
        beforeEnter(el) {
            el.style.opacity = 0;
        },
        enter(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.6s';
            el.style.opacity = 1;
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 0;
            done();
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    text-align: center;
    font-size: 18px;
    color: $primary-color;
    font-family: 'Roboto-Light', 'sans-serif';
}

form {
    padding: 0 20px;
}

.input-group {
    margin-bottom: 8px;
    display: flex;
    gap: 8px;
}

.buttons-group {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
}

.button {
    cursor: pointer;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 16px;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 10px;
    color: $tertiary-color;
    background-color: $primary-color;
    border: 2px solid transparent;
    transition: all 0.3s ease;
}

.button:hover {
    color: $primary-color;
    background-color: $text-container-color;
}

.remind-password {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 5px;
    margin-bottom: 10px;
}

.remind-password-button {
    cursor: pointer;
    color: $remind-password;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 10px;
    background-color: transparent;
    border: none;
    transition: color 0.3s ease;
}

.remind-password-button:hover {
    color: $primary-color;
}

.input {
    color: $white;
    font-size: 14px;
    width: 100%;
    padding: 8px;
    background-color: $secondary-color;
    outline: none;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    border-radius: 10px;
}

.input:hover,
.input:focus {
    border: 2px solid $primary-color;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal {
    z-index: 1000;
}

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
</style>