<template>
    <div class="billing-settings">
        <p class="title">WYBIERZ SWÓJ SYSTEM ROZLICZEŃ</p>
        <div class="settings">
            <label>
                <input type="radio" name="billingOption" id="weekly" value="weekly" v-model="billingCycle"
                    @change="confirmBillingCycleChange('weekly')" />
                Co tydzień
            </label>
            <label>
                <input type="radio" name="billingOption" id="biweekly" value="bi-weekly" v-model="billingCycle"
                    @change="confirmBillingCycleChange('bi-weekly')" />
                Co dwa tygodnie
            </label>
            <label>
                <input type="radio" name="billingOption" id="monthly" value="monthly" v-model="billingCycle"
                    @change="confirmBillingCycleChange('monthly')" />
                Raz w miesiącu (pierwszy poniedziałek nowego miesiąca nastąpi rozliczenie)
            </label>
        </div>
        <p class="date" v-if="billingStartDate && latestBillingCycle">{{ billingStartDate }}</p>
        <p class="date" v-if="billingEndDate && latestBillingCycle">{{ billingEndDate }}</p>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            billingCycle: this.$store.state.billingCycle || '',
        };
    },
    computed: {
        currentBillingCycle() {
            return this.$store.state.billingCycle;
        },
        latestBillingCycle() {
            if (this.$store.state.user && this.$store.state.user.billing_cycles) {
                const cycles = this.$store.state.user.billing_cycles;
                return cycles.sort((a, b) => new Date(b.end_date) - new Date(a.end_date))[0];
            }
            return null;
        },
        billingStartDate() {
            const startDate = this.latestBillingCycle ? this.latestBillingCycle.start_date : '';
            return startDate ? `Data rozpoczęcia cyklu: ${this.formatDate(startDate)} 00:00` : 'Brak dostępnej daty rozpoczęcia cyklu.';
        },
        billingEndDate() {
            const endDate = this.latestBillingCycle ? this.latestBillingCycle.end_date : '';
            return endDate ? `Data zakończenia cyklu: ${this.formatDate(endDate)} 23:59` : 'Brak dostępnej daty zakończenia cyklu.';
        },
    },
    watch: {
        currentBillingCycle(newValue) {
            this.billingCycle = newValue;
        },
    },
    methods: {
        formatDate(dateString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return new Date(dateString).toLocaleDateString('pl-PL', options);
        },
        async confirmBillingCycleChange(newBillingCycle) {
            const result = await Swal.fire({
                title: 'Czy na pewno chcesz ustawić nowy cykl rozliczeń?',
                text: `Chcesz ustawić cykl rozliczeń na: ${newBillingCycle === 'weekly' ? 'Co tydzień' :
                    newBillingCycle === 'bi-weekly' ? 'Co dwa tygodnie' :
                        'Raz w miesiącu (pierwszy tydzień nowego miesiąca nastąpi rozliczenie)'
                    }. Jeśli zmieniasz istniejący cykl, zmiana wejdzie w życie dopiero w kolejnym cyklu rozliczeniowym.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Tak, ustaw cykl',
                cancelButtonText: 'Anuluj',
            });

            if (result.isConfirmed) {
                try {
                    await this.$store.dispatch('updateBillingCycle', newBillingCycle);

                    const successResult = await Swal.fire({
                        title: 'Zmiana cyklu zakończona sukcesem!',
                        text: `Cykl rozliczeń został zmieniony na: ${newBillingCycle === 'weekly' ? 'Co tydzień' : newBillingCycle === 'bi-weekly' ? 'Co dwa tygodnie' : 'Raz w miesiącu (pierwszy tydzień nowego miesiąca nastąpi rozliczenie)'}`,
                        icon: 'success',
                        confirmButtonText: 'OK',
                    });

                    if (successResult.isConfirmed) {
                        this.billingCycle = newBillingCycle;
                    }

                } catch (error) {
                    await Swal.fire({
                        title: 'Wystąpił błąd!',
                        text: 'Nie udało się zmienić cyklu rozliczeń. Spróbuj ponownie później.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                }
            } else {
                this.billingCycle = this.$store.state.billingCycle || '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.billing-settings {
    margin: 0 auto;
    padding: 20px;
    font-size: 24px;
    color: $white;
    height: 100vh;
}

.title {
    text-align: left;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;

    &:after {
        content: '';
        display: block;
        width: 50px;
        height: 3px;
        background: $primary-color;
        margin-top: 5px;
        margin-left: 2px;
    }
}

.date {
    font-size: 16px;
    text-align: left;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
}

.settings {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto-Extra-Light', sans-serif;
    gap: 15px;
}

input[type="radio"] {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 3px solid $secondary-color;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    outline: none;
    flex-shrink: 0;

    &:checked {
        background-color: $primary-color;
    }
}

label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $white;
}
</style>
