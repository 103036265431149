<template>
    <div class="user-table">
        <div class="filter-bar">
            <div class="filter-dropdown">
                <select v-model="selectedFilter" class="filter-select">
                    <option v-for="(option, index) in filterOptions" :key="index" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <input type="text" v-model="filterValue" placeholder="WPISZ WARTOŚĆ..." class="filter-input" />
        </div>

        <div v-if="loading && users.length === 0">
            <div class="skeleton-list">
                <div v-for="n in 5" :key="n" class="skeleton-card">
                    <div class="skeleton-header"></div>
                    <div class="skeleton-line short"></div>
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                </div>
            </div>
        </div>

        <div v-else class="cards-container">
            <template v-for="user in paginatedUsers" :key="user.id">
                <div class="user-card" @click="toggleRow(user.id)"
                    :class="{ 'expanded': clickedUserId === user.id || loadingRowId === user.id }">
                    <div class="card-header">
                        <span class="user-id">{{ user.id }}</span>
                    </div>

                    <div class="user-info">
                        <p><strong>{{ user.first_name }} {{ user.last_name }}</strong></p>
                        <p><strong>PESEL: </strong>{{ user.pesel }}</p>
                        <p><strong>Nr tel:</strong> {{ user.phone_number }}</p>
                        <p><strong>Email:</strong> {{ user.email }}</p>
                        <p><strong>Aplikacje: </strong>
                            <span v-if="user.bolt_driver">Bolt</span>
                            <span v-if="user.bolt_driver && (user.uber_driver || user.freenow_driver)">, </span>
                            <span v-if="user.uber_driver">Uber</span>
                            <span v-if="user.uber_driver && user.freenow_driver">, </span>
                            <span v-if="user.freenow_driver">Freenow</span>
                            <span v-if="!user.freenow_driver && !user.uber_driver && !user.bolt_driver">Brak danych</span>
                        </p>
                        <p><strong>Złoty Program: </strong>
                            <span v-if="user.gold_program">Tak (Kod: {{ goldProgamData.code }}, Pozostało użyć: {{ goldProgamData.quantity_use}}/5, Rabat: {{ goldProgamData.discount }})</span>
                            <span v-else>Nie</span>
                        </p>
                        <hr class="separator">
                        <p><strong>Pojazd:</strong> {{ user.survey_answers?.carType || 'Brak danych' }}</p>

                        <div class="email-status">
                            <i :class="{
                                'fas fa-check-circle': user.email_verified_at,
                                'fas fa-times-circle': !user.email_verified_at
                            }"></i>
                            <span>{{ user.email_verified_at ? 'Zweryfikowano' : 'Niezweryfikowano' }}</span>
                        </div>
                    </div>

                    <div class="user-details" v-if="clickedUserId === user.id || loadingRowId === user.id">
                        <template v-if="loadingRowId === user.id">
                            <div class="loader">Ładowanie...</div>
                        </template>
                        <template v-else>
                            <p class="date">
                                Wybrany typ cyklu:
                                <span>{{ currentBillingCycle }}</span>
                            </p>
                            <p class="date">
                                Data rozpoczęcia cyklu:
                                <span>{{ billingStartDate }}</span>
                            </p>
                            <p class="date">
                                Data zakończenia cyklu:
                                <span>{{ billingEndDate }}</span>
                            </p>
                            <button v-if="clickedUserId === user.id" @click="showDetails(user.id)"
                                class="details-button">Przejdź na konto</button>
                        </template>
                    </div>


                    <div class="expand-info">
                        <i class="fas fa-chevron-down" :class="{ 'rotated': clickedUserId === user.id }"></i>
                        <span>{{ clickedUserId === user.id ? 'Zwiń szczegóły' : 'Rozwiń szczegóły' }}</span>
                    </div>
                </div>
            </template>

        </div>

        <div class="pagination-controls">
            <button class="pagination-button" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
                :class="{ 'active': currentPage > 1, 'disabled': currentPage === 1 }">
                <i class="fas fa-chevron-left"></i>
            </button>
            <span class="current-page">Strona {{ currentPage }} z {{ totalPages }}</span>
            <button class="pagination-button" @click="changePage(currentPage + 1)"
                :disabled="currentPage === totalPages"
                :class="{ 'active': currentPage < totalPages, 'disabled': currentPage === totalPages }">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from "vuex";

export default {
    data() {
        return {
            selectedFilter: "id",
            selectedFilterLabel: "ID",
            filterValue: "",
            isDropdownOpen: false,
            filterOptions: [{
                value: "id",
                label: "ID"
            },
            {
                value: "first_name",
                label: "IMIĘ"
            },
            {
                value: "last_name",
                label: "NAZWISKO"
            },
            {
                value: "pesel",
                label: "PESEL"
            },
            {
                value: "email",
                label: "E-MAIL"
            },
            ],
            currentPage: 1,
            recordsPerPage: 5,
            loadingRowId: null,
            clickedUserId: null,
            loading: false,
        };
    },
    computed: {
        ...mapState({
            users: (state) => state.users,
            isAuthenticated: (state) => state.isAuthenticated,
        }),
        filteredUsers() {
            return this.users
                .filter((user) => {
                    if (!this.filterValue) return true;
                    return String(user[this.selectedFilter])
                        .toLowerCase()
                        .includes(this.filterValue.toLowerCase());
                });
        },
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.recordsPerPage;
            return this.filteredUsers.slice(start, start + this.recordsPerPage);
        },
        totalPages() {
            return Math.ceil(this.filteredUsers.length / this.recordsPerPage);
        },

        currentBillingCycle() {
            let userBillingCycle = this.$store.state.user.billing_cycle;

            if (userBillingCycle === 'weekly') {
                userBillingCycle = 'Tygodniowy';
            } else if (userBillingCycle === 'bi-weekly') {
                userBillingCycle = 'Dwutygodniowy';
            } else if (userBillingCycle === 'monthly') {
                userBillingCycle = 'Miesięczny';
            } else {
                userBillingCycle = 'Brak cyklu';
            }

            return userBillingCycle;
        },
        goldProgamData() {
            return this.$store.state.user.gold_program;
        },
        latestBillingCycle() {
            if (this.$store.state.user && this.$store.state.user.billing_cycles) {
                const cycles = this.$store.state.user.billing_cycles;
                return cycles.sort((a, b) => new Date(b.end_date) - new Date(a.end_date))[0];
            }
            return null;
        },
        billingStartDate() {
            const startDate = this.latestBillingCycle ? this.latestBillingCycle.start_date : '';
            return startDate ? `${this.formatDate(startDate)} 00:00` : 'Brak dostępnej daty rozpoczęcia cyklu.';
        },
        billingEndDate() {
            const endDate = this.latestBillingCycle ? this.latestBillingCycle.end_date : '';
            return endDate ? `${this.formatDate(endDate)} 23:59` : 'Brak dostępnej daty zakończenia cyklu.';
        },
    },
    created() {
        this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            this.loading = true;
            try {
                await this.$store.dispatch("fetchUsers");
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                this.loading = false;
            }
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectFilter(value, label) {
            this.selectedFilter = value;
            this.selectedFilterLabel = label;
            this.isDropdownOpen = false;
        },
        changePage(page) {
            if (page < 1 || page > this.totalPages) return;
            this.currentPage = page;
        },
        toggleRow(userId) {
            if (this.clickedUserId === userId) {
                this.clickedUserId = null;
                this.loadingRowId = null;
            } else {
                this.clickedUserId = userId;
                this.loadingRowId = userId;

                this.$store.dispatch('fetchUserById', userId)
                    .finally(() => {
                        this.loadingRowId = null;
                    });
            }
        },
        showDetails(userId) {
            this.$router.push({
                name: 'DriverDetails',
                params: {
                    id: userId
                }
            });
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            };
            return new Date(dateString).toLocaleDateString('pl-PL', options);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-table {
    font-family: "Roboto-Light", sans-serif;
    margin: 20px;
}

.filter-bar {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background-color: $tr_color;
    border-radius: 12px;
    padding: 12px 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-dropdown {
    position: relative;
    width: 20%;
}

.filter-select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    background-color: $tr_color;
    color: $white;
    border: 1px solid $white;
    border-radius: 12px;
    outline: none;
    border: none;
}

.filter-select option {
    background-color: $tr_color;
    color: $white;
    padding: 10px;
}

.filter-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    border-left: none;
    border-radius: 0 12px 12px 0;
    outline: none;
    color: $white;
}

.cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-card {
    background-color: $messages-color;
    color: $white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    max-height: 250px;
}

.user-card.expanded {
    max-height: 600px;
    padding: 20px;
}

.user-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    background-color: $text-container-color;
    cursor: pointer;
}

.card-header {
    background-color: transparent;
    padding: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.user-id {
    color: $placeholder-color;
}

.user-info p {
    margin: 5px 0;
}

.separator {
    border: 0;
    border-top: 2px solid $primary-color;
}

.email-status {
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
}

.email-status i {
    margin-right: 5px;
    color: $white;
}

.email-status i.fa-check-circle {
    color: $success-color;
}

.email-status i.fa-times-circle {
    color: $warning-color;
}

.email-status span {
    font-size: 12px;
    color: $white;
}

.user-details {
    margin-top: 20px;
    background-color: $secondary-color;
    padding: 15px;
    border-radius: 10px;
}

.date {
    color: $white;

    span {
        color: $primary-color;
    }
}

.details-button {
    margin-top: 5px;
    background-color: $primary-color;
    color: $tertiary-color;
    padding: 12px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: "Roboto-Light", sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
    width: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: $tertiary-color;
        color: $primary-color;
    }
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-button {
    padding: 10px 15px;
    border: none;
    border-radius: 12px;
    background-color: $tr_color;
    color: $white;
    font-size: 14px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: $tr_color;
    }

    &.active {
        background-color: $primary-color;
    }

    &:hover:not(.disabled) {
        background-color: darken($tr_color, 10%);
        transform: scale(1.05);
    }
}

.current-page {
    margin: 0 10px;
    font-weight: bold;
    color: $white;
}

.expand-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: $white;
    font-size: 14px;
    cursor: pointer;

    i {
        margin-right: 5px;
        font-size: 16px;
        transition: transform 0.3s ease;
    }

    .rotated {
        transform: rotate(180deg);
    }
}

.user-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.skeleton-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.skeleton-card {
    background-color: #2c2c2c;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
}

.skeleton-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 150%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    animation: shine 1.5s infinite;
}

.skeleton-header {
    width: 30%;
    height: 20px;
    background-color: #3b3b3b;
    border-radius: 5px;
}

.skeleton-line {
    width: 100%;
    height: 15px;
    background-color: #3b3b3b;
    border-radius: 5px;
}

.skeleton-line.short {
    width: 60%;
}

@keyframes shine {
    0% {
        left: -150%;
    }

    50% {
        left: 100%;
    }

    100% {
        left: 150%;
    }
}

@media (max-width: 768px) {
    .user-table {
        height: auto;
    }

    .filter-dropdown {
        width: 80%;
    }

    .user-card {
        padding: 15px;
    }

    .details-button {
        width: 100%;
    }
}
</style>