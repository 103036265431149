import { createRouter, createWebHistory } from 'vue-router';
import store from '../src/store';
import HomePage from './components/Views/HomePage/HomePage.vue';
import OfferPage from './components/Views/OfferPage/OfferPage.vue';
import BlogPage from './components/Views/Blog/BlogPage.vue';
import ProfilePage from './components/Views/Profile/ProfilePage.vue';
import SurveyPage from './components/Views/Profile/SurveyPage.vue';
import AdminPanel from './components/Views/AdminPanel/AdminPanel.vue';
import DriverDetails from './components/Views/Profile/DriverDetails.vue';
import FAQComponent from './components/Views/FAQ/FAQComponent.vue';
import RentalContract from './assets/documents/RentalContract.vue';
import MandateContract from './assets/documents/MandateContract.vue';
import AuthorizationPage from './components/Views/Authorization/AuthorizationPage.vue';
import AccessDeniedPage from './components/Common/AccessDeniedPage.vue';
import NotFound from './components/Common/NotFound.vue';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Cabbie - Strona nie znaleziona'
    }
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDeniedPage,
    meta: {
      title: 'Cabbie - Brak dostępu'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'Cabbie'
    }
  },
  {
    path: '/offer',
    name: 'Offer',
    component: OfferPage,
    meta: {
      title: 'Cabbie - Oferta'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogPage,
    meta: {
      title: 'Cabbie - Blog'
    }
  },
  {
    path: '/profile/:section?',
    name: 'Profile',
    component: ProfilePage,
    meta: {
      title: 'Cabbie - Panel użytkownika',
      requiresAuth: true,
      requiresEmailVerification: true 
    }
  },
  {
    path: '/survey',
    name: 'Survey',
    component: SurveyPage,
    meta: {
      title: 'Cabbie - Formularz początkowy',
      requiresAuth: true 
    }
  },
  {
    path: '/admin-panel/:section?',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {
      title: 'Cabbie - Panel administratora',
      requiresAuth: true 
    }
  },
  {
    path: '/driver/:id',
    name: 'DriverDetails',
    component: DriverDetails,
    meta: {
      title: 'Cabbie - Szczegóły kierowcy',
      requiresAuth: true 
    }
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQComponent,
    meta: {
      title: 'Cabbie - FAQ'
    }
  },
  {
    path: '/umowa-najmu',
    name: 'RentalContract',
    component: RentalContract,
    meta: {
      title: 'Cabbie - Umowa Najmu',
      requiresAuth: true,
      requiresEmailVerification: true
    }
  },
  {
    path: '/umowa-zlecenie',
    name: 'MandateContract',
    component: MandateContract,
    meta: {
      title: 'Cabbie - Umowa Zlecenie',
      requiresAuth: true,
      requiresEmailVerification: true 
    }
  },
  {
    path: '/authorization',
    name: 'AuthorizationPage',
    component: AuthorizationPage,
    meta: {
      title: 'Cabbie - Autoryzacja'
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isLoggedIn;
  const userRole = store.getters.getRole;
  // const isEmailVerificated = store.getters.isEmailVerified;

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'AccessDenied' });
  } 
  else if (to.name === 'AdminPanel' && userRole !== 'admin') {
    next({ name: 'AccessDenied' });
  }
  // else if (to.meta.requiresEmailVerification && !isEmailVerificated && to.path !== '/profile/details' && to.path !== '/profile') {
  //   next({ name: 'AccessDenied' });
  // }
  else {
    next();
  }
});


export default router;