<template>
    <div class="gold-program">
        <div
            ref="textContainer"
            class="text-container"
            :class="{ 'is-visible': isVisible }"
        >
            <p class="title">ZŁOTY PROGRAM</p>
            <p class="description">
                Nasza akcja promocyjna <span class="highlight">“Złoty program”</span> zapewnia zredukowanie kosztu
                rozliczenia na stałe.
            </p>
            <p class="description">
                <span class="highlight">Jeden Polecony kierowca = 2 złote mniej od ceny rozliczenia</span>
            </p>
            <p class="description">
                Liczba miejsc mocno ograniczona - zaloguj się, aby zapisać się do programu.
            </p>
            <a class="regulations" href="https://cloud.cabbie.pl/index.php/s/8qJBmAatKQtoGNG" target="_blank">Regulamin akcji</a>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isVisible = ref(false);
const textContainer = ref(null);

const handleIntersection = (entries) => {
    if (entries[0].isIntersecting) {
        isVisible.value = true;
    }
};

let observer;

onMounted(() => {
    observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.5, // Threshold, kiedy element stanie się widoczny
    });
    if (textContainer.value) {
        observer.observe(textContainer.value);
    }
});

onUnmounted(() => {
    if (observer && textContainer.value) {
        observer.unobserve(textContainer.value);
    }
});
</script>

<style lang="scss" scoped>
.gold-program {
    background-image: url('@/assets/images/Group 40.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
}

.text-container {
    position: relative;
    width: 70%;
    background-image: linear-gradient(140deg, rgba(135, 135, 135, 0.1) 1%, rgba(135, 135, 135, 0.1) 100%);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    text-align: center;
    padding: 40px;
    opacity: 0; /* Początkowo niewidoczny */
    transform: translateY(50px); /* Początkowa pozycja */
    transition: opacity 1s ease-out, transform 1s ease-out; /* Animacja */
}

.text-container.is-visible {
    opacity: 1; 
    transform: translateY(0); /* Zmiana pozycji po wejściu */
}

.text-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    padding: 1px;
    background: linear-gradient(to right, #C6C6C6, #727272);
    transform: scale(0.995);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

.title {
    text-align: center;
    font-size: 36px;
    font-family: 'Roboto-Light', sans-serif;
    color: $primary-color;
}

.description {
    font-size: 22px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $title-light-font;
    text-align: center;
}

.highlight {
    color: $primary-color;
    font-weight: bold;
}

.regulations {
    margin-top: 20px;
    font-size: 24px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $title-light-font;
    z-index: 10;
    position: relative;
}

@media (max-width: 768px) {
    .gold-program {
        height: auto;
        padding-right: 0;
    }

    .text-container {
        margin: 100px 20px 100px 20px;
        padding: 20px;
        height: auto;
        width: 100vw;
    }

    .title {
        font-size: 30px;
    }

    .description {
        font-size: 20px;
    }
}
</style>