<template>
  <div
    class="how-to-start"
    ref="howToStart"
    :class="{ 'is-visible': isVisible }"
  >
    <p class="title">JAK ZACZĄĆ</p>

    <div class="steps">
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="{ 'is-visible': isVisible }"
        class="step"
      >
        <img :src="step.image" :alt="step.alt" class="step-image" />
        <p class="step-text">{{ step.text }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

import RegisterImage from '@/assets/images/Register.svg';
import DocumentsImage from '@/assets/images/Documents.svg';
import SignImage from '@/assets/images/Sign.svg';
import AppsImage from '@/assets/images/Apps.svg';
import AdjustImage from '@/assets/images/Adjust.svg';

const steps = ref([
  {
    image: RegisterImage,
    alt: 'Register',
    text: 'Zarejestruj się w panelu Cabbie.',
  },
  {
    image: DocumentsImage,
    alt: 'Documents',
    text: 'Dostarcz wymagane dokumenty. Otrzymasz plik PDF z listą oraz wskazówkami jak je zdobyć.',
  },
  {
    image: SignImage,
    alt: 'Sign',
    text: 'Podpisanie Umów. Możliwość podpisywania umów elektronicznie dzięki współpracy z Autenti. (JUŻ WKRÓTCE)',
  },
  {
    image: AppsImage,
    alt: 'Apps',
    text: 'Rejestracja w aplikacjach Bolt, Uber, Free now.',
  },
  {
    image: AdjustImage,
    alt: 'Adjust',
    text: 'Dostosuj zarobki do swoich potrzeb, a obsługę i wsparcie zostaw nam.',
  },
]);

const isVisible = ref(false);
const howToStart = ref(null);

const handleIntersection = (entries) => {
  if (entries[0].isIntersecting) {
    isVisible.value = true;
  }
};

let observer;

onMounted(() => {
  observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.2,
  });
  if (howToStart.value) {
    observer.observe(howToStart.value);
  }
});

onUnmounted(() => {
  if (observer && howToStart.value) {
    observer.unobserve(howToStart.value);
  }
});
</script>

<style lang="scss" scoped>
.how-to-start {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title,
.step {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s ease-out, transform 1s ease-out; 
}

.how-to-start.is-visible .title,
.how-to-start.is-visible .step {
  opacity: 1;
  transform: translateY(0);
}

.title {
  text-align: center;
  font-size: 36px;
  font-family: 'Roboto-Light', sans-serif;
  color: $primary-color;
  margin-bottom: 40px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: 'Roboto-Light', sans-serif;
}

.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
}

.step-image {
  width: 60px;
  height: 60px;
}

.step-text {
  font-size: 16px;
  color: $white;
  line-height: 1.5;
  max-width: 500px;
  text-align: left;
}

@media (max-width: 768px) {
  .step {
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  .step-image {
    width: 100px;
    height: 100px;
  }

  .step-text {
    font-size: 18px;
    padding: 0 10px;
    text-align: center;
  }
}
</style>