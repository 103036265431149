<template>
    <div class="opinions">
        <p class="review">{{ reviewText }}</p>
        <div class="rating">
            <i v-for="n in 5" :key="n" :class="getStarClass(n)"></i>
        </div>
        <p class="author">{{ authorName }}</p>
    </div>
</template>

<script>
export default {
    props: {
        reviewText: {
            type: String,
            required: true
        },
        authorName: {
            type: String,
            required: true
        },
        rating: {
            type: Number,
            required: true
        }
    },
    methods: {
        getStarClass(starNumber) {
            if (starNumber <= this.rating) {
                return 'fas fa-star';
            } else {
                return 'far fa-star';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.opinions {
    height: 250px;
    width: 250px;
    background-color: $secondary-color;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $white;
    overflow: hidden;
}

.review {
    height: 50%;
    text-align: center;
    font-family: 'Roboto-Extra-Light', sans-serif;
    padding: 0 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $scroll-track;
        border-radius: 10px;
    }
}

.author {
    text-align: center;
    font-family: 'Roboto-Light', sans-serif;
}

.rating {
    display: flex;
    justify-content: center;
}

.rating i {
    color: $primary-color;
    margin: 0 2px;
    font-size: 16px;
}
</style>