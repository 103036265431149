<template>
    <div class="contact" ref="contact" :class="{ 'is-visible': isVisible }">
        <p class="title" :class="{ 'is-visible': isVisible }">SKONTAKTUJ SIĘ Z NAMI</p>
        <form class="contact-form" @submit.prevent="submitContactForm" :class="{ 'is-visible': isVisible }">
            <div class="input-row">
                <input v-model="firstName" type="text" placeholder="IMIĘ" class="input-field" required />
                <input v-model="lastName" type="text" placeholder="NAZWISKO" class="input-field" required />
                <input v-model="email" type="email" placeholder="E-MAIL" class="input-field" required />
            </div>
            <textarea v-model="message" placeholder="TREŚĆ TWOJEJ WIADOMOŚCI" class="input-description" required></textarea>
            <button type="submit" class="send">WYŚLIJ</button>
        </form>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'; 
import { useStore } from 'vuex';

const store = useStore();

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const message = ref('');

const isVisible = ref(false);
const contact = ref(null);

const submitContactForm = async () => {
    try {
        await store.dispatch('sendContactEmail', {
            first_name: firstName.value,
            last_name: lastName.value,
            email: email.value,
            message: message.value
        });
        
        firstName.value = '';
        lastName.value = '';
        email.value = '';
        message.value = '';
    } catch (error) {
        console.error('Błąd podczas wysyłania formularza:', error);
    }
};

onMounted(() => {
    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            isVisible.value = true;
        }
    }, { threshold: 0.5 });

    if (contact.value) {
        observer.observe(contact.value);
    }
});
</script>

<style lang="scss" scoped>
.contact {
    padding: 0 100px;
    opacity: 0;
    transform: translateY(5px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.contact.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.title {
    text-align: left;
    font-size: 30px;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.title.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.contact-form.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.input-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.input-field {
    padding: 8px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    border: 2px solid transparent;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: $secondary-color;
    color: $white;
    transition: border 0.3s ease;
    flex: 1;
    outline: none;
}

.input-description {
    padding: 10px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    border: 2px solid transparent;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: $secondary-color;
    color: $white;
    transition: border 0.3s ease;
    height: 150px;
    resize: none;
    outline: none;
    overflow-y: auto;
}

.input-description::-webkit-scrollbar {
    width: 8px;
}

.input-description::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 10px;
}

.input-description::-webkit-scrollbar-track {
    background: $scroll-track;
    border-radius: 10px;
}

.input-field:hover,
.input-field:focus,
.input-description:hover,
.input-description:focus {
    border: 2px solid $primary-color;
}

.send {
    background-color: $primary-color;
    color: $white;
    border: 2px solid $primary-color;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Roboto-Light', 'sans-serif';
    font-size: 18px;
    padding: 8px 15px;
    width: 120px;
    margin-bottom: 30px;
    transition: all 0.3s ease;
    color: $tertiary-color;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);
}

.send:hover {
    color: $primary-color;
    background-color: $text-container-color;
    border: 2px solid $tertiary-color;
}

@media (max-width: 768px) {
    .contact {
        height: auto;
        padding: 0 20px;
    }

    .input-row {
        flex-direction: column;
        gap: 10px;
    }

    .contact-form {
        gap: 10px;
    }
}
</style>