<template>
    <div class="cabbie-offer">
        <div ref="textContainer" class="text-container" :class="{ 'is-visible': isVisible }">
            <p class="title">DLACZEGO KIEROWCY WYBIERAJĄ WSPÓŁPRACĘ Z CABBIE?</p>
            <div v-for="(offer, index) in filteredOffers" :key="index" :class="{ 'is-visible': isVisible }"
                class="offer-item">
                <p class="description-title">{{ offer.title }}</p>
                <p class="description">{{ offer.description }}</p>
                <div class="strip" v-if="index < filteredOffers.length - 1"></div>
            </div>
            <router-link to="/offer" class="offer-button" :class="{ 'is-visible': isVisible }">
                Zobacz ofertę
            </router-link>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();

        const offers = ref([
            { title: "BRAK PROWIZJI OD TWOICH ZAROBKÓW", description: "Oferujemy kierowcom pełne zarobki bez ukrytych prowizji." },
            { title: "ELASTYCZNE ROZLICZENIA", description: "Dopasuj je do swoich potrzeb." },
            { title: "INDYWIDUALNY GRAFIK", description: "Będąc kierowcą Cabbie, sam ustalasz godziny pracy." },
            { title: "ZŁOTY PROGRAM", description: "Zyskaj permanentne obniżenie kosztów rozliczeń." },
        ]);

        const getGoldProgramSlots = computed(() => store.getters.getGoldProgramSlots);

        const isVisible = ref(false);
        const textContainer = ref(null);

        const filteredOffers = computed(() => {
            if (getGoldProgramSlots.value) {
                return offers.value;
            } else {
                return offers.value.filter(offer => offer.title !== "ZŁOTY PROGRAM");
            }
        });

        const handleIntersection = (entries) => {
            if (entries[0].isIntersecting) {
                isVisible.value = true;
            }
        };

        let observer;

        onMounted(() => {
            observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
            if (textContainer.value) {
                observer.observe(textContainer.value);
            }
        });

        onUnmounted(() => {
            if (observer && textContainer.value) {
                observer.unobserve(textContainer.value);
            }
        });

        return {
            filteredOffers,
            isVisible,
            textContainer,
        };
    },
};
</script>

<style lang="scss" scoped>
.cabbie-offer {
    background-color: $primary-color;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-container {
    text-align: center;
}

.title,
.offer-item,
.offer-button {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.text-container.is-visible .title,
.text-container.is-visible .offer-item,
.text-container.is-visible .offer-button {
    opacity: 1;
    transform: translateY(0);
}

.title {
    font-size: 24px;
    font-family: 'Roboto-Light', sans-serif;
    text-align: center;
    color: $tertiary-color;
    margin-bottom: 40px;
}

.offer-item {
    margin-bottom: 20px;
}

.description-title {
    font-size: 20px;
    font-family: 'Roboto-Light', sans-serif;
}

.description {
    padding: 0px 20px 0px 20px;
    font-size: 16px;
    font-family: 'Roboto-Extra-Light', sans-serif;
}

.strip {
    height: 0.5px;
    border-radius: 25px;
    width: 60%;
    background-color: $tertiary-color;
    margin: 0 auto;
}

.offer-button {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    background-color: $secondary-color;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);
}

.offer-button:hover {
    background-color: darken($secondary-color, 10%);
}
</style>
