<template>
    <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="loading" class="loading-modal">
            <LoaderComponent class="loading" />
        </div>
    </transition>

    <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="isModalOpen" class="modal" @click.self="closeModal">
            <div class="modal-content">
                <img :src="'data:image/jpeg;base64,' + modalImageBase64" alt="Podgląd faktury" />
                <button class="close-button" @click="closeModal">Zamknij</button>
            </div>
        </div>
    </transition>

    <div :class="['driver-details', { 'centered': isCentered }]">
        <button v-if="getRole === 'admin'" class="back-button" @click="goBack">
            <i class="fas fa-arrow-left"></i>
        </button>

        <div v-if="getGoldProgramSlots">
            <div v-if="getRole != 'admin' && !isGoldProgramMember" class="golden-program-section">
                <h2 class="section-title">Dołącz do Złotego Programu</h2>
                <p class="section-description">
                    Polecaj Cabbie innym i zyskaj rabat do 10 zł na stałe rozliczenie! Skorzystaj z wyjątkowej oferty
                    już teraz. Ilość miejsc ograniczona!
                </p>
                <div class="subscription-form">
                    <div class="checkbox-container">
                        <input type="checkbox" v-model="acceptedTerms" id="terms-checkbox" required />
                        <label for="terms-checkbox">
                            Akceptuję <a href="https://cloud.cabbie.pl/index.php/s/8qJBmAatKQtoGNG"
                                target="_blank">Regulamin</a>
                        </label>
                    </div>
                    <button @click="registerToGoldenProgram" class="submit-button" :disabled="!acceptedTerms">Zapisz
                        się</button>
                </div>
            </div>
        </div>

        <div v-if="getRole != 'admin' && isGoldProgramMember" class="golden-program-section">
            <h2 class="section-title">Złoty Program</h2>
            <p class="section-description">
                Gratulacje! Jesteś uczestnikiem Złotego Programu. Skorzystaj z przywilejów i zbieraj zniżki. Oto
                szczegóły:
            </p>
            <div class="golden-program-info">
                <div class="golden-program-item">
                    <h3>Twój kod</h3>
                    <p>
                        <span ref="codeText" style="cursor: pointer;">
                            {{ user.gold_program?.code }}
                        </span>
                        <i @click="copyCode" class="copy-button fas fa-copy" title="Kopiuj"></i>
                    </p>

                </div>
                <div class="golden-program-item">
                    <h3>Pozostało użyć</h3>
                    <p>{{ user.gold_program?.quantity_use }} / 5</p>
                </div>
                <div class="golden-program-item">
                    <h3>Zniżka</h3>
                    <p>{{ user.gold_program?.discount }} zł</p>
                </div>
            </div>
        </div>

        <p class="title">DANE KIEROWCY</p>
        <div class="input-row">
            <input type="text" placeholder="IMIĘ" v-model="user.first_name" class="input-field"
                :readonly="!isEditingDriver" />
            <input type="text" placeholder="NAZWISKO" v-model="user.last_name" class="input-field"
                :readonly="!isEditingDriver" />
        </div>

        <div class="input-row">
            <div class="input-container">
                <input type="text" placeholder="E-MAIL" v-model="user.email" class="input-field" readonly />
                <i v-if="!isEmailVerified" class="warning-icon fas fa-circle-exclamation"
                    title="Nie zweryfikowano maila"></i>
                <i v-if="isEmailVerified" class="verified-icon fas fa-check-circle" title="E-mail zweryfikowany"></i>
            </div>
            <input type="text" placeholder="NR TELEFONU" v-model="user.phone_number" class="input-field"
                :readonly="!isEditingDriver" />
        </div>

        <div class="input-row single">
            <input type="text" placeholder="NR PESEL" v-model="user.pesel" class="input-field pesel"
                :readonly="!isEditingDriver" />
        </div>

        <div class="input-row last-row">
            <input type="text" placeholder="NAZWA BANKU" v-model="user.bank_name" class="input-field"
                :readonly="!isEditingDriver" />
            <input type="text" placeholder="NR KONTA BANKU" v-model="user.bank_account_number" class="input-field"
                :readonly="!isEditingDriver" />
        </div>

        <div class="input-row last-row">
            <input type="text" placeholder="KOD POCZTOWY" v-model="user.postal_code" class="input-field"
                :readonly="!isEditingDriver" />
            <input type="text" placeholder="MIASTO" v-model="user.living_city" class="input-field"
                :readonly="!isEditingDriver" />
        </div>

        <div class="input-row">
            <input type="text" placeholder="ULICA" v-model="user.street" class="input-field"
                :readonly="!isEditingDriver" />
            <input type="text" placeholder="NR BUDYNKU/MIESZKANIA" v-model="user.building_number" class="input-field"
                :readonly="!isEditingDriver" />
        </div>

        <div v-if="!isEmailVerified && getRole != 'admin'" class="resend-verification-container">
            <button class="resend-verification" @click="resendVerificationEmail(user.id)">Wyślij ponownie e-mail
                weryfikacyjny</button>
        </div>

        <div v-if="getRole != 'admin'" class="button-container">
            <button v-if="!isEditingDriver" class="edit" @click="startEditingDriver">EDYTUJ</button>
            <button v-if="isEditingDriver" class="save" @click="saveChangesDriver">ZAPISZ</button>
            <button v-if="isEditingDriver" class="cancel" @click="cancelEditingDriver">ANULUJ</button>
        </div>

        <p class="title">DANE POJAZDU</p>
        <div class="input-row">
            <input type="text" placeholder="MARKA" v-model="car.brand" class="input-field"
                :readonly="!(isEditingCar && (getUserCarType || getRole === 'admin'))" />
            <input type="text" placeholder="MODEL" v-model="car.model" class="input-field"
                :readonly="!(isEditingCar && (getUserCarType || getRole === 'admin'))" />
        </div>

        <div class="input-row">
            <input type="text" placeholder="ROK PRODUKCJI" v-model="car.year" class="input-field"
                :readonly="!(isEditingCar && (getUserCarType || getRole === 'admin'))" />
            <input type="text" placeholder="NR VIN" v-model="car.vin" class="input-field"
                :readonly="!(isEditingCar && (getUserCarType || getRole === 'admin'))" />
        </div>

        <div class="input-row single">
            <input type="text" placeholder="NR REJESTRACJI" v-model="car.registration_number" class="input-field"
                :readonly="!(isEditingCar && (getUserCarType || getRole === 'admin'))" />
        </div>

        <p v-if="!this.getUserCarType && getRole != 'admin' && (!car || !car.brand || !car.model || !car.registration_number)"
            class="custom-label">
            Proces rejestracji samochodu, który został dla Ciebie przydzielony, może potrwać do 7 dni roboczych. Prosimy
            o cierpliwość – poinformujemy Cię, gdy pojazd będzie gotowy do użytku.
        </p>

        <div v-if="(getUserCarType && getRole == 'client') || (!getUserCarType && getRole == 'admin')"
            class="button-container">
            <button v-if="!isEditingCar" class="car-edit" @click="startEditingCar">EDYTUJ</button>
            <button v-if="isEditingCar" class="car-save" @click="saveChangesCar">ZAPISZ</button>
            <button v-if="isEditingCar" class="car-cancel" @click="cancelEditingCar">ANULUJ</button>
        </div>

        <p v-if="getRole === 'admin'" class="title">APLIKACJE</p>
        <div class="input-row app" v-if="getRole === 'admin'">
            <label for="bolt_driver_id">BOLT</label>
            <input type="text" id="bolt_driver_id" v-model="user.bolt_driver_id" placeholder="IDENTYFIKATOR BOLT"
                class="input-field" :readonly="!isEditingApp" />
        </div>
        <div class="input-row app" v-if="getRole === 'admin'">
            <label for="uber_driver_id">UBER</label>
            <input type="text" id="uber_driver_id" v-model="user.uber_driver_id" placeholder="IDENTYFIKATOR UBER"
                class="input-field" :readonly="!isEditingApp" />
        </div>
        <div class="input-row app" v-if="getRole === 'admin'">
            <label for="freenow_driver_id">FREENOW</label>
            <input type="text" id="freenow_driver_id" v-model="user.freenow_driver_id"
                placeholder="IDENTYFIKATOR FREENOW" class="input-field" :readonly="!isEditingApp" />
        </div>
        <div v-if="getRole === 'admin'" class="button-container">
            <button v-if="!isEditingApp" class="edit" @click="startEditingApp">EDYTUJ</button>
            <button v-if="isEditingApp" class="save" @click="saveChangesApp">ZAPISZ</button>
            <button v-if="isEditingApp" class="cancel" @click="cancelEditingApp">ANULUJ</button>
        </div>

        <div v-if="getRole === 'admin'" class="verify-form-row">
            <p v-if="user.has_license_documents" class="success-custom-label">KIEROWCA GOTOWY DO WYROBIENIA LICENCJI</p>
            <p v-if="!user.has_license_documents" class="warning-custom-label">KIEROWCA NIE JEST GOTOWY DO WYROBIENIA
                LICENCJI</p>
        </div>

        <div class="verify-form-row" v-if="getRole === 'admin'">
            <div class="info-container">
                <p class="second-custom-label">CZY ZWERYFIKOWANY FORMULARZ OSOBOWY:</p>
            </div>
            <div :class="['custom-dropdown', { open: dropdownOpen, 'success-bg': isFormVerified, 'warning-bg': !isFormVerified }]"
                @click="toggleDropdown">
                <div class="dropdown-selected">
                    {{ isFormVerified ? 'TAK' : 'NIE' }}
                </div>
                <ul :class="['dropdown-list', { open: dropdownOpen }]">
                    <li @click="selectOption(true)">TAK</li>
                    <li @click="selectOption(false)">NIE</li>
                </ul>
            </div>
        </div>

        <div class="invoices-container" v-if="getRole === 'admin'">
            <div class="invoices-status">
                <p class="title">Status dodanych Faktur</p>

                <div class="custom-select">
                    <div class="selected" :class="{ 'no-border-radius': isDropdownOpen }" @click="secondToggleDropdown">
                        {{ selectedDateText || 'Wybierz termin' }}
                        <span class="arrow" :class="{ 'open': isDropdownOpen }">
                            <i class="fas fa-angle-down"></i>
                        </span>
                    </div>
                    <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                        <div class="options" v-if="isDropdownOpen">
                            <div v-if="billingCycles.length === 0" class="option no-options">
                                Brak dostępnych terminów
                            </div>
                            <div v-else class="option" v-for="(date, index) in billingCycles" :key="index"
                                @click="selectDate(date)" @mouseover="hoverIndex = index"
                                @mouseleave="hoverIndex = null" :class="{ hover: hoverIndex === index }">
                                {{ formatDate(date.start_date) }} - {{ formatDate(date.end_date) }}
                            </div>
                        </div>
                    </transition>
                </div>

                <p v-if="!invoices || invoices.length === 0" class="no-invoices">Brak faktur</p>

                <div v-else class="invoice" v-for="(invoice, index) in invoices" :key="index">
                    <div class="accept-invoice">
                        <span class="invoice-number" @click="openModal(invoice.image_base64)">
                            Faktura z {{ invoice.invoice_date }}
                        </span>

                        <select v-model="invoice.status" class="invoice-status-select"
                            @change="showInvoiceStatusButton(invoice)">
                            <option value="Weryfikacja" class="status-Weryfikacja">Weryfikacja</option>
                            <option value="Zaakceptowana" class="status-Zaakceptowana">Zaakceptowana</option>
                            <option value="Odrzucona" class="status-Odrzucona">Odrzucona</option>
                        </select>

                        <div>
                            <button class="send" @click="updateInvoiceStatus(invoice.id, invoice.status)">Wyślij
                                Status</button>
                        </div>
                    </div>

                    <div v-if="invoice.status === 'Odrzucona' && invoice.isManuallySelected">
                        <textarea v-model="invoice.rejectionReason" placeholder="Wpisz powód odrzucenia..."
                            class="rejection-textarea"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="survey-answers-container" v-if="getRole === 'admin'">
            <div v-if="getUserSurveyData" @click="toggleAnswers">
                <p class="survey-title">ODPOWIEDZI Z ANKIETY</p>
                <div class="survey-answers" v-bind:class="{ 'slide-open': show }">
                    <p class="survey-answer">1. Miasto <strong class="survey-answer-highlight">{{ userSurveyData.city }}
                        </strong></p>
                    <p class="survey-answer">2. Czy jesteś aktywnym kierowcą aplikacji Taxi? <strong
                            class="survey-answer-highlight">{{ userSurveyData.isDriver }} </strong></p>
                    <p class="survey-answer">3. Samochód <strong class="survey-answer-highlight">{{
                        userSurveyData.carType }}</strong></p>
                    <p class="survey-answer" v-if="userSurveyData.taxtRegistry != null">2.1 Czy posiada wpis TAXI w
                        dowodzie? <strong class="survey-answer-highlight">{{ userSurveyData.taxtRegistry }}</strong></p>
                    <p class="survey-answer">4. Status <strong class="survey-answer-highlight">{{
                        userSurveyData.jobStatus }}</strong></p>
                    <p class="survey-answer">5. Jak szybko chcesz rozpaczać pracę? <strong
                            class="survey-answer-highlight">{{ userSurveyData.startTime }}</strong></p>
                    <p class="survey-answer">6. Ile czasu Tygodniowo chcesz przeznaczyć na prace jak kierowca TAXI?
                        <strong class="survey-answer-highlight">{{ userSurveyData.weeklyHours }}</strong>
                    </p>
                    <p class="survey-answer">7. Skąd się dowiedziałeś o Cabbie? <strong
                            class="survey-answer-highlight">{{ userSurveyData.foundVia }}</strong></p>
                </div>
            </div>
            <div v-else class="empty-survey-container">
                <p class="empty-survey-title">
                    UŻYTKOWNIK NIE WYPEŁNIŁ ANKIETY
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from '@/components/Common/LoaderComponent.vue';
import jsPDF from "jspdf";
import apiService from "@/apiService";
import Swal from 'sweetalert2';
import {
    mapGetters,
    mapActions
} from 'vuex';
import {
    useToast
} from 'vue-toastification';

export default {
    components: {
        LoaderComponent
    },
    data() {
        return {
            loading: false,
            isEditingDriver: false,
            isEditingCar: false,
            isEditingApp: false,
            originalUser: {},
            dropdownOpen: false,
            show: false,
            isCentered: false,
            isModalOpen: false,
            modalImageBase64: null,
            latestBillingCycle: null,
            invoiceStatusButton: false,
            isDropdownOpen: false,
            selectedBillingCycle: null,
            carData: {
                brand: '',
                model: '',
                year: '',
                vin: '',
                registration_number: ''
            },
            acceptedTerms: false,
            isGoldProgramMember: false
        };
    },
    computed: {
        ...mapGetters(['getUser', 'isEmailVerified', 'getRole', 'isFormVerified', 'getUserSurveyData', 'getUserCarType', 'getUserId', 'getCar', 'getGoldProgramSlots']),
        user() {
            return this.getUser || {};
        },
        car() {
            return this.getCar || {};
        },
        userSurveyData() {
            return this.getUserSurveyData;
        },
        invoices() {
            return this.$store.state.invoices;
        },
        billingCycles() {
            return this.$store.state.billingCycles;
        },
        selectedDateText() {
            return this.selectedBillingCycle ?
                `${this.formatDate(this.selectedBillingCycle.start_date)} - ${this.formatDate(this.selectedBillingCycle.end_date)}` : '';
        },
    },
    async created() {
        if (this.getRole === 'admin') {
            const userId = this.$route.params.id;
            await this.fetchUserById(userId);
            const latestBillingCycle = this.$store.state.latestBillingCycle;

            if (latestBillingCycle) {
                await this.$store.dispatch('fetchInvoicesByBillingCycle', {
                    billingCycleId: latestBillingCycle.id,
                    userId,
                });
            }

            if (this.$store.state.user && this.$store.state.user.gold_program) {
                this.isGoldProgramMember = true;
            }

            await this.$store.dispatch('fetchUserSurveyData', {
                userId
            });
        } else {
            this.$store.dispatch('fetchUserId').then(() => {
                const userIdForUser = this.getUserId;

                this.$store.dispatch('fetchUserSurveyData', {
                    userId: userIdForUser
                });
                this.$store.dispatch('fetchCar', userIdForUser);

                if (this.$store.state.user && this.$store.state.user.gold_program) {
                    this.isGoldProgramMember = true;
                }
            }).catch((error) => {
                console.error("Error fetching user ID:", error);
            });
        }
    },
    mounted() {
        if (this.getRole === 'admin') {
            setTimeout(() => {
                if (this.isFieldsEmpty()) {
                    this.showAlert();
                }
            }, 500);
        }
        if (this.$route.params && this.$route.params.id) {
            this.isCentered = true;
        }
        this.initialState = {
            bolt_driver_id: this.user.bolt_driver_id,
            uber_driver_id: this.user.uber_driver_id,
            freenow_driver_id: this.user.freenow_driver_id,
        };
    },
    watch: {
        billingCycles: {
            handler(newBillingCycles) {
                if (newBillingCycles.length > 0 && !this.selectedBillingCycle) {
                    const latestCycle = newBillingCycles.reduce((latest, current) =>
                        new Date(current.start_date) > new Date(latest.start_date) ? current : latest
                    );
                    this.selectedBillingCycle = latestCycle;
                    this.selectDate(latestCycle);
                }
            },
            immediate: true,
        },
        '$store.state.user': function (newUser) {
            if (newUser && newUser.gold_program) {
                this.isGoldProgramMember = true;
            }
        },
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchUserById', 'updateUser', 'resendVerificationEmail', 'updateFormVerificationStatus', 'addCar']),
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        secondToggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },

        formatDate(date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        async selectOption(value) {
            this.isFormVerified = value;
            this.dropdownOpen = false;
            const userId = this.user.id;

            try {
                await this.$store.dispatch('updateFormVerificationStatus', {
                    userId,
                    isVerified: value
                });
                Swal.fire({
                    title: 'Sukces!',
                    text: 'Status weryfikacji formularza został zaktualizowany.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
            } catch (error) {
                Swal.fire({
                    title: 'Błąd!',
                    text: error.message || 'Nie udało się zaktualizować statusu weryfikacji formularza.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
            this.dropdownOpen = false;
        },
        startEditingDriver() {
            this.isEditingDriver = true;
            this.originalUser = {
                ...this.user
            };
        },
        startEditingApp() {
            this.isEditingApp = true;
        },
        startEditingCar() {
            this.isEditingCar = true;
        },
        async saveChangesDriver() {
            this.loading = true;
            try {
                await this.updateUser(this.user);
                this.isEditingDriver = false;
                Swal.fire({
                    title: 'Sukces!',
                    text: 'Dane zostały zaktualizowane pomyślnie.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
            } catch (error) {
                console.error('Error updating user:', error);
                Swal.fire({
                    title: 'Błąd!',
                    text: 'Wystąpił problem z aktualizacją danych. Spróbuj ponownie.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                this.loading = false;
            }
        },
        async saveChangesCar() {
            if (!this.car.brand && !this.car.model && !this.car.year && !this.car.vin) {
                if (this.car.id) {
                    this.loading = true;
                    try {
                        await this.$store.dispatch('deleteCar', this.car.id);
                        this.isEditingCar = false;
                        Swal.fire({
                            title: 'Sukces!',
                            text: 'Samochód został usunięty pomyślnie.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        });
                    } catch (error) {
                        Swal.fire({
                            title: 'Błąd!',
                            text: 'Nie udało się usunąć samochodu. Spróbuj ponownie.',
                            icon: 'error',
                            confirmButtonText: 'OK',
                        });
                    } finally {
                        this.loading = false;
                    }
                } else {
                    Swal.fire({
                        title: 'Błąd!',
                        text: 'Nie znaleziono samochodu do usunięcia.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                }
            } else {
                if (this.car.id) {
                    try {
                        await this.$store.dispatch('updateCar', {
                            carId: this.car.id,
                            carData: this.car
                        });
                        this.isEditingCar = false;
                        Swal.fire({
                            title: 'Sukces!',
                            text: 'Dane samochodu zostały zaktualizowane pomyślnie.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        });
                    } catch (error) {
                        Swal.fire({
                            title: 'Błąd!',
                            text: error.message || 'Nie udało się zaktualizować danych samochodu.',
                            icon: 'error',
                            confirmButtonText: 'OK',
                        });
                    } finally {
                        this.loading = false;
                    }
                } else {
                    try {
                        await this.$store.dispatch('addCar', {
                            ...this.car,
                            user_id: this.user.id
                        });
                        this.isEditingCar = false;
                        Swal.fire({
                            title: 'Sukces!',
                            text: 'Pojazd został dodany pomyślnie.',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        });
                    } catch (error) {
                        Swal.fire({
                            title: 'Błąd!',
                            text: error.message || 'Wystąpił błąd przy dodawaniu samochodu.',
                            icon: 'error',
                            confirmButtonText: 'OK',
                        });
                    } finally {
                        this.loading = false;
                    }
                }
            }
            this.$store.dispatch('fetchCar', this.user.id);
        },

        async saveChangesApp() {
            const driverIds = {};
            const clearFields = [];

            if (this.user.bolt_driver_id !== this.initialState.bolt_driver_id) {
                if (this.user.bolt_driver_id) {
                    driverIds.bolt_driver_id = this.user.bolt_driver_id;
                } else {
                    clearFields.push({ application: 'Bolt', id: this.user.id });
                }
            }

            if (this.user.uber_driver_id !== this.initialState.uber_driver_id) {
                if (this.user.uber_driver_id) {
                    driverIds.uber_driver_id = this.user.uber_driver_id;
                } else {
                    clearFields.push({ application: 'Uber', id: this.user.id });
                }
            }

            if (this.user.freenow_driver_id !== this.initialState.freenow_driver_id) {
                if (this.user.freenow_driver_id) {
                    driverIds.freenow_driver_id = this.user.freenow_driver_id;
                } else {
                    clearFields.push({ application: 'FreeNow', id: this.user.id });
                }
            }

            this.loading = true;

            try {

                if (Object.keys(driverIds).length > 0) {
                    await apiService.put(`/users/${this.user.id}/driver-ids`, driverIds);
                }

                for (const field of clearFields) {
                    await apiService.delete(`/remove-driver-application/${field.id}`, { data: { application: field.application } });
                }

                this.isEditingApp = false;
                Swal.fire({
                    title: 'Sukces!',
                    text: 'Zmiany zostały zapisane pomyślnie.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
            } catch (error) {
                console.error('Error saving changes:', error);
                Swal.fire({
                    title: 'Błąd!',
                    text: 'Wystąpił problem z zapisem zmian. Spróbuj ponownie.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                this.loading = false;
            }
        },
        cancelEditingDriver() {
            this.user = {
                ...this.originalUser
            };
            this.isEditingDriver = false;
        },
        cancelEditingCar() {
            this.isEditingCar = false;
        },
        cancelEditingApp() {
            this.isEditingApp = false;
        },
        async resendVerificationEmail(userId) {
            try {
                await this.$store.dispatch('resendVerificationEmail', userId);
                Swal.fire({
                    title: 'E-mail wysłany!',
                    text: 'Nowy e-mail weryfikacyjny został wysłany pomyślnie.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
            } catch (error) {
                console.error('Error resending verification email:', error);
                Swal.fire({
                    title: 'Błąd!',
                    text: 'Nie udało się wysłać ponownie e-maila weryfikacyjnego.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        showAlert() {
            Swal.fire({
                title: 'Uwaga!',
                text: 'Proszę wrócić do poprzedniej strony.',
                icon: 'warning',
                confirmButtonText: 'OK',
            }).then(() => {
                this.goBack();
            });
        },
        isFieldsEmpty() {
            return !this.user.first_name && !this.user.last_name && !this.user.email && !this.user.phone_number && !this.user.pesel && !this.user.bank_name && !this.user.bank_account_number;
        },
        toggleAnswers() {
            this.show = !this.show;
        },
        openModal(imageBase64) {
            this.modalImageBase64 = imageBase64;
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
            this.modalImageBase64 = null;
        },
        beforeEnter(el) {
            el.style.opacity = 0;
        },
        enter(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 1;
            el.style.transform = 'translateY(0)';
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 0;
            setTimeout(() => {
                done();
            }, 300);
        },
        async updateInvoiceStatus(invoiceId, newStatus) {
            const invoice = this.invoices.find(inv => inv.id === invoiceId);

            const payload = {
                invoiceId,
                newStatus,
            };

            if (newStatus === 'Odrzucona' && invoice.rejectionReason) {
                payload.rejectionReason = invoice.rejectionReason;
            }

            this.loading = true;

            try {
                await this.$store.dispatch('updateInvoiceStatus', payload);

                if (newStatus === 'Zaakceptowana' && invoice.image_base64) {
                    await this.sendInvoiceAsPDF(invoice);
                }
                Swal.fire({
                    title: 'Sukces!',
                    text: 'Status faktury został zaktualizowany.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });

                invoice.isManuallySelected = false;
            } catch (error) {
                Swal.fire({
                    title: 'Błąd!',
                    text: error.message || 'Nie udało się zaktualizować statusu faktury.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                this.loading = false;
            }
        },
        async sendInvoiceAsPDF(invoice) {
            try {
                if (!invoice.image_base64) {
                    throw new Error('Brak obrazu w formacie Base64!');
                }

                const doc = new jsPDF();
                const imgData = `data:image/jpeg;base64,${invoice.image_base64}`;

                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();

                doc.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);

                const formattedDate = this.formatDate(invoice.invoice_date);
                const fileName = `Faktura z ${formattedDate}.pdf`;

                const pdfBlob = doc.output('blob');
                const formData = new FormData();
                formData.append('file', pdfBlob, fileName);

                const userId = this.$route.params.id;
                await this.fetchUserById(userId);

                const { first_name, last_name } = this.user;
                const city = this.userSurveyData?.city;

                if (!city) {
                    throw new Error('Miasto nie zostało znalezione w ankiecie!');
                }

                formData.append('first_name', first_name);
                formData.append('last_name', last_name);
                formData.append('city', city);

                const response = await apiService.post('/driver/upload/faktura', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 201) {
                    return response;
                } else {
                    throw new Error('Błąd podczas wysyłania faktury');
                }
            } catch (error) {
                throw new Error(error.message || 'Nie udało się wysłać faktury.');
            }
        },
        showInvoiceStatusButton(invoice) {
            if (invoice.status === 'Odrzucona' || invoice.status === 'Zaakceptowana') {
                this.invoiceStatusButton = true;
                invoice.isManuallySelected = true;
            } else {
                this.invoiceStatusButton = false;
                invoice.isManuallySelected = false;
            }
        },
        selectDate(date) {
            this.selectedBillingCycle = date;
            this.isDropdownOpen = false;
            const userId = this.$route.params.id
            const currentDate = new Date();
            this.isLatestCycle = new Date(date.end_date) >= currentDate;
            this.$store.dispatch('fetchInvoicesByBillingCycle', {
                billingCycleId: date.id,
                userId,
            })
                .catch((error) => {
                    console.error('Błąd ładowania faktur:', error);
                });
        },
        async registerToGoldenProgram() {
            this.loading = true;
            try {
                await apiService.post('/gold-program/join', {});
                Swal.fire({
                    title: 'Sukces!',
                    text: 'Zostałeś pomyślnie zapisany do programu.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                });
                this.isGoldProgramMember = true;

                const updatedUser = await this.fetchUserById(this.getUserId);
                this.user = updatedUser;
            } catch (error) {
                if (error.response && error.response.data) {
                    Swal.fire({
                        title: 'Błąd',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                }
            } finally {
                this.loading = false;
            }
        },
        copyCode() {
            const codeText = this.$refs.codeText;
            const toast = useToast();

            if (codeText) {
                const textToCopy = codeText.textContent || codeText.innerText;
                console.log("Copied content: ", textToCopy);

                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        console.log("Code copied to clipboard!");
                        toast.warning('Kod został skopiowany do schowka!', {
                            timeout: 2000
                        });
                    })
                    .catch(err => {
                        console.error("Error copying text: ", err);
                    });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.driver-details {
    max-width: 710px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &.centered {
        margin: 0 auto;
    }
}

.title {
    text-align: left;
    font-size: 24px;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
    margin-left: 0;

    &:after {
        content: '';
        display: block;
        width: 50px;
        height: 3px;
        background: $primary-color;
        margin-top: 5px;
        margin-left: 2px;
    }
}

.input-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.app {
    flex-direction: column;
}

label {
    font-family: 'Roboto-Light', sans-serif;
    color: $placeholder-color;
}

.verify-form-row {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    margin-left: 5px;
}

.input-row.last-row {
    margin-top: 30px;
}

.input-row.single {
    justify-content: left;
}

.input-container {
    position: relative;
}

.input-field {
    padding: 10px;
    width: 350px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    border: 2px solid transparent;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: $secondary-color;
    color: $placeholder-color;
    transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    outline: none;
    z-index: 1;
}

.input-field[readonly] {
    cursor: default;
}

.input-field:not([readonly]) {
    color: $white;
    cursor: text;
}

.input-field::placeholder {
    color: $placeholder-color;
}

.warning-icon,
.verified-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
}

.warning-icon {
    color: $warning-color;
}

.verified-icon {
    color: $success-color;
}

.success-custom-label {
    font-size: 16px;
    color: $white;
    margin-right: 10px;
    font-family: 'Roboto-Light', sans-serif;
    color: $success-color;
}

.warning-custom-label {
    font-size: 16px;
    color: $white;
    margin-right: 10px;
    font-family: 'Roboto-Light', sans-serif;
    color: $warning-color;
}

.warning-icon::after,
.verified-icon::after {
    content: attr(title);
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(-10px);
    padding: 5px;
    background-color: $secondary-color;
    color: $white;
    border-radius: 3px;
    white-space: nowrap;
    font-family: 'Roboto-Extra-Light', sans-serif;
    font-size: 15px;
    display: none;
}

.warning-icon:hover::after,
.verified-icon:hover::after {
    display: block;
}

.resend-verification-container {
    margin-top: 20px;
    text-align: left;
}

.resend-verification {
    margin-left: 5px;
    background: none;
    color: $title-light-font;
    border: none;
    font-family: 'Roboto-Light', sans-serif;
    font-size: 16px;
    cursor: pointer;
    padding: 0;
}

.resend-verification:hover {
    text-decoration: underline;
}

.edit,
.save,
.car-edit,
.car-save {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 16px;
    color: $tertiary-color;
    background-color: $primary-color;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);

    &:hover {
        background-color: $text-container-color;
        color: $primary-color;
    }
}

.send {
    padding: 10px;
    font-size: 14px;
    margin-left: 10px;
    color: $tertiary-color;
    background-color: $primary-color;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);

    &:hover {
        background-color: $text-container-color;
        color: $primary-color;
    }
}

.cancel,
.car-cancel {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 16px;
    color: $white;
    background-color: $text-container-color;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);

    &:hover {
        background-color: $remind-password;
    }
}

.button-container {
    display: flex;
    justify-content: flex-start;
}

.back-button {
    position: absolute;
    background-color: transparent;
    border: none;
    color: $primary-color;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.back-button i {
    margin-right: 5px;
}

.back-button:hover {
    color: $secondary-color;
}

.custom-label,
.second-custom-label {
    font-size: 16px;
    color: $white;
    margin-right: 10px;
    font-family: 'Roboto-Light', sans-serif;
}

.second-custom-label {
    line-height: 0.5;
}

.custom-dropdown {
    font-family: 'Roboto-Light', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: $secondary-color;
    border: none;
    border-radius: 15px;
    color: $white;
    width: 100px;
    text-align: center;
    user-select: none;
    transition: border-radius 0.3s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.custom-dropdown.open {
    border-radius: 15px 15px 0 0;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $secondary-color;
    border: none;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 0 0 15px 15px;
    z-index: 10;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.dropdown-list.open {
    max-height: 200px;
    opacity: 1;
}

.dropdown-list li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-list li:hover {
    background-color: $primary-color;
}

.success-bg {
    background-color: $success-color;
}

.warning-bg {
    background-color: $warning-color;
}

.survey-answers-container {
    background-color: $tr_color;
    margin-top: 30px;
    border-radius: 15px;
    cursor: pointer;
}

.survey-title {
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
    margin-left: 20px;
}

.survey-answers {
    background-color: $secondary-color;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.survey-answer {
    color: $footer-background;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 20px;
    margin-right: 10px;
    font-family: 'Roboto-Light', sans-serif;
    opacity: 1;
}

.survey-answer-highlight {
    color: $white;
}

.empty-survey-container {
    padding: 5px;
}

.empty-survey-title {
    color: $white;
    font-family: 'Roboto-Light', sans-serif;
    margin-left: 20px;
}

.survey-answers {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-open {
    max-height: 500px;
    opacity: 1;
}

.info-message {
    font-size: 10px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $white;
    line-height: 0.3;
}

.invoices-status {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 600px;
}

.no-invoices {
    text-align: left;
    font-family: 'Roboto-Light', sans-serif;
    color: $white;
    font-size: 16px;
}

.invoice {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    color: $white;
    font-family: 'Roboto-Light', sans-serif;
    border-bottom: 1px solid $background-color;
    font-size: 16px;
}

.invoice-number {
    margin-top: 10px;
    margin-right: auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.invoice-number:hover {
    color: $primary-color;
}

.invoice-status-select {
    padding: 6px;
    border: transparent;
    border-radius: 15px;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    color: $primary-color;
    transition: all 0.3s ease-in-out;
}

.invoice-status-select:hover {
    background-color: $secondary-color;
}

.invoice-status-select:focus {
    outline: none;
    background-color: $secondary-color;
}

.invoice-status-select option:hover {
    color: $white;
}

.status-Weryfikacja {
    color: $primary-color;
}

.status-Zaakceptowana {
    color: $success-color;
}

.status-Odrzucona {
    color: $warning-color;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: transparent;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content img {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 8px;
    margin-bottom: 10px;
}

.close-button {
    padding: 8px 16px;
    background-color: $text-container-color;
    color: $white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.close-button:hover {
    background-color: $remind-password;
}

.accept-invoice {
    display: flex;
    flex-direction: row;
}

.rejection-textarea {
    background-color: $secondary-color;
    border-radius: 10px;
    width: 95%;
    height: 100px;
    font-size: 14px;
    font-family: 'Roboto-Light', sans-serif;
    margin-top: 20px;
    resize: none;
    outline: none;
    border: 1px solid transparent;
    color: $white;
    padding: 15px;
    transition: all 0.3s ease-in-out;

    &:hover {
        border: 1px solid $primary-color;
    }

    &:focus {
        border: 1px solid $primary-color;
    }
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 14px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    background-color: $secondary-color;
    border-radius: 0 0 10px 10px;
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);
}

.option {
    padding: 10px;
    cursor: pointer;
    color: $white;

    &:hover {
        background-color: $tr_color;
    }
}

.custom-select {
    position: relative;
    width: 300px;
    color: $white;
}

.selected {
    padding: 10px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    background-color: $secondary-color;
    color: $placeholder-color;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border-radius 0.3s ease;

    &.no-border-radius {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.taxi-id {
    background-color: $primary-color;
    color: $tertiary-color;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: $text-container-color;
        color: $primary-color;
    }
}

.golden-program-section {
    background-color: $primary-color;
    color: $tertiary-color;
    text-align: left;
    border-radius: 15px;
    padding: 20px 30px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);
    font-family: 'Roboto-Light', sans-serif;
}

.section-title {
    font-size: 24px;
    margin: 0;
}

.section-description {
    font-size: 16px;
}

.subscription-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 6px;

    label {
        color: $tertiary-color;

        a {
            color: $tertiary-color;
            text-decoration: underline;
        }
    }
}

.submit-button {
    background-color: $tertiary-color;
    color: $white;
    padding: 12px 24px;
    font-size: 1rem;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);
}

.submit-button:hover:not(:disabled) {
    background-color: $quaternary-color;
}

.submit-button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

.checkbox-container input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid $tertiary-color;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s;
}

.checkbox-container input[type="checkbox"]:checked {
    background-color: $tertiary-color;
    border-color: $tertiary-color;
    position: relative;
}

.checkbox-container input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 4px;
    width: 6px;
    height: 10px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.golden-program-info {
    display: flex;
    justify-content: space-between;
}

.copy-button {
    background-color: transparent;
    color: $tertiary-color;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.copy-button:hover {
    transform: scale(1.2);
}

@media (max-width: 768px) {

    .input-row,
    .verify-form-row {
        flex-direction: column;
    }

    .input-field {
        width: 100%;
    }

    .custom-dropdown {
        padding: 10px;
    }

    .second-custom-label {
        line-height: 1.2;
        margin: 10px 0px;
    }

    .rejection-textarea {
        width: 90%;
    }

    .custom-select {
        width: 100%;
    }

    .section-description {
        text-align: justify;
    }

    .golden-program-info {
        flex-direction: column;
        gap: 0px;
    }
}
</style>