<template>
  <div class="offer">
    <p class="title" ref="titleRef">NASZA OFERTA</p>
    <main>
      <section id="settlements">
        <SettlementsSection />
      </section>
      <section id="second-section">
        <SecondSection />
      </section>
      <section id="car-fleet">
        <CarFleetSection />
      </section>
      <section v-if="getGoldProgramSlots" id="gold-program">
        <GoldProgramSection />
      </section>
      <section v-if="!getGoldProgramSlots" id="gold-program">
        <ContactForm />
      </section>
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import SettlementsSection from './SettlementsSection.vue';
import SecondSection from './SecondSection.vue';
import CarFleetSection from './CarFleetSection.vue';
import GoldProgramSection from './GoldProgramSection.vue';
import ContactForm from '../HomePage/ContactForm.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SettlementsSection,
    SecondSection,
    CarFleetSection,
    GoldProgramSection,
    ContactForm
  },
  computed: {
    ...mapGetters(['getGoldProgramSlots']),
  },
  setup() {
    const titleRef = ref(null);

    onMounted(() => {
      setTimeout(() => {
        if (titleRef.value) {
          titleRef.value.classList.add('is-visible');
        }
      }, 100);
    });

    return {
      titleRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-size: 36px;
  font-family: 'Roboto-Light', sans-serif;
  color: $primary-color;
  margin-top: 100px;
  padding: 50px 0px 50px 0px;

  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.title.is-visible {
  opacity: 1;
  transform: translateY(0);
}

main section {
  width: 100%;
  margin: 0;
}
</style>