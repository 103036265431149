<template>
    <div class="cabbie-panel">
      <div
        ref="textContainer"
        class="text-container"
        :class="{ 'is-visible': isVisible }"
      >
        <p class="title">PANEL CABBIE</p>
        <p class="description">
          <span class="highlight">Zautomatyzowany</span> system obliczający wynagrodzenia oraz podatki za Ciebie.
        </p>
        <p class="description">
          <span class="highlight">Wsparcie</span> 7 dni w tygodniu.
        </p>
        <p class="description">
          Kampanie wspomagające <span class="highlight">rozwój</span> oraz <span class="highlight">oszczędność</span> poprzez długotrwałą współpracę.
        </p>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  const isVisible = ref(false);
  const textContainer = ref(null);
  
  const handleIntersection = (entries) => {
    if (entries[0].isIntersecting) {
      isVisible.value = true;
    }
  };
  
  let observer;
  
  onMounted(() => {
    observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });
    if (textContainer.value) {
      observer.observe(textContainer.value);
    }
  });
  
  onUnmounted(() => {
    if (observer && textContainer.value) {
      observer.unobserve(textContainer.value);
    }
  });
  </script>
  
  <style lang="scss" scoped>
  .cabbie-panel {
    background-image: url('@/assets/images/woman-wanting-pay-taxi-services 1-placeholder.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
    box-sizing: border-box;
  }
  
  .text-container {
    position: relative;
    width: 70%;
    background-image: linear-gradient(140deg, rgba(135, 135, 135, 0.1) 1%, rgba(135, 135, 135, 0.1) 100%);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    text-align: right;
    padding: 40px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  
    &.is-visible {
      opacity: 1; 
      transform: translateY(0);
    }
  }
  
  .text-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    padding: 1px;
    background: linear-gradient(to right, #C6C6C6, #727272);
    transform: scale(0.995);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
  
  .title {
    text-align: right;
    font-size: 36px;
    font-family: 'Roboto-Light', sans-serif;
    color: $primary-color;
  }
  
  .description {
    font-size: 22px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $title-light-font;
  }
  
  .highlight {
    color: $primary-color;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .cabbie-panel {
      height: 80vh;
      padding-right: 0;
      background-position: 40%;
    }
  
    .text-container {
      margin: 20px;
      padding: 20px;
    }
  
    .title {
      font-size: 30px;
    }
  
    .description {
      font-size: 20px;
    }
  }
  </style>  