<template>
    <div class="main-container">
        <h1 class="title">ROZLICZENIA</h1>
        <div class="header-buttons">
            <button class="send" @click="getBillings">PRZEŚLIJ ROZLICZENIA DO KIEROWCÓW</button>
            <button class="nextcloud" @click="goToNextcloud">PRZEJDŹ DO NEXTCLOUD</button>
        </div>

        <div class="links-section">
            <p class="title">Pobrane Kwoty</p>
            <ul>
                <li v-for="(link, index) in pobraneKwotyLinks" :key="index" class="link-item">
                    <a :href="link.url" target="_blank">{{ link.name }}</a>
                </li>
            </ul>
        </div>

        <div class="links-section">
            <p class="title">Wypłaty Kierowców</p>
            <ul>
                <li v-for="(link, index) in wyplatyKierowcowLinks" :key="index" class="link-item">
                    <a :href="link.url" target="_blank">{{ link.name }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pobraneKwotyLinks: [
                { name: 'Pobrane kwoty cykl 1', url: 'https://example.com/pobrane1' },
                { name: 'Pobrane kwoty cykl 2', url: 'https://example.com/pobrane2' },
                { name: 'Pobrane kwoty cykl 3', url: 'https://example.com/pobrane3' },
                { name: 'Pobrane kwoty cykl 4', url: 'https://example.com/pobrane4' }
            ],
            wyplatyKierowcowLinks: [
                { name: 'Wypłaty kierowców cykl 1', url: 'https://example.com/wyplata1' },
                { name: 'Wypłaty kierowców cykl 2', url: 'https://example.com/wyplata2' },
                { name: 'Wypłaty kierowców cykl 3', url: 'https://example.com/wyplata3' },
                { name: 'Wypłaty kierowców cykl 4', url: 'https://example.com/wyplata4' }
            ]
        };
    },
    methods: {
        getBillings() {
            // some logic
        },
        goToNextcloud() {
            const nextcloudUrl = 'https://cloud.cabbie.pl/index.php/apps/dashboard/';
            window.open(nextcloudUrl, '_blank');
        }
    }
};
</script>

<style lang="scss" scoped>
.main-container {
    margin: 20px;
}

.title {
    color: $white;
    font-family: 'Roboto-Light', sans-serif;
}

.header-buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    button {
        padding: 10px 20px;
        font-family: 'Roboto-Light', sans-serif;
        font-size: 16px;
        color: $tertiary-color;
        background-color: $primary-color;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

        &:hover {
            background-color: $text-container-color;
            color: $primary-color;
        }
    }
}

.links-section {
    margin-top: 30px;

    p.title {
        font-family: 'Roboto-Light', sans-serif;
        font-size: 20px;
        margin-bottom: 10px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        .link-item {
            margin: 10px 0;

            a {
                text-decoration: none;
                font-family: 'Roboto-Light', sans-serif;
                font-size: 18px;
                color: $footer-background;
                transition: color 0.3s ease;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .header-buttons {
        flex-direction: column;
        gap: 10px;
    }
}
</style>