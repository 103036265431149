<template>
    <div class="fleet">
        <div class="text-container">
            <p class="title" ref="titleRef" :class="{ 'is-visible': isTitleVisible }">FLOTA</p>
            <p class="description" ref="descriptionRef" v-html="description"
                :class="{ 'is-visible': isDescriptionVisible }"></p>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const description = ref("Posiadamy swoją flotę samochodów oraz zaufanych partnerów wynajmujących. Auta są w pełni przystosowane do pracy. Dzięki współpracy z branżą motoryzacyjną otrzymasz zniżki na części oraz naprawy w zaufanych punktach.");

const isTitleVisible = ref(false);
const isDescriptionVisible = ref(false);

const titleRef = ref(null);
const descriptionRef = ref(null);

onMounted(() => {
    const titleObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                isTitleVisible.value = true;
            }
        });
    }, { threshold: 0.2 });

    const descriptionObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                isDescriptionVisible.value = true;
            }
        });
    }, { threshold: 0.2 });

    if (titleRef.value) {
        titleObserver.observe(titleRef.value);
    }

    if (descriptionRef.value) {
        descriptionObserver.observe(descriptionRef.value);
    }
});
</script>

<style lang="scss" scoped>
.fleet {
    background-color: $secondary-color;
    display: flex;
    justify-content: left;
}

.text-container {
    padding: 0 0 50px 50px;
    text-align: left;
}

.title {
    font-size: 36px;
    font-family: 'Roboto-Light', sans-serif;
    color: $primary-color;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.title.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.description {
    width: 80%;
    font-size: 22px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $title-light-font;
    text-align: justify;
    text-justify: inter-word;
    word-wrap: break-word;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.description.is-visible {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 768px) {
    .text-container {
        padding: 0 20px 50px 20px;
    }

    .title {
        font-size: 28px;
    }

    .description {
        width: 100%;
        font-size: 18px;
    }
}
</style>