<template>
    <div v-if="isLoading" class="wallet-loader">
        <div class="billing-cycle-loader">
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
        </div>
        <div class="summary-section-loader">
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
        </div>
        <div class="upload-invoice-loader">
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
        </div>
        <div class="invoice-status-loader">
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
        </div>
    </div>
    <div v-else class="wallet">
        <div v-if="billingCycles.length === 0">
            <p class="no-cycle-message">Brak rozliczenia.</p>
        </div>

        <div v-if="billingCycles.length > 0" class="billing-cycle">
            <p class="title">Portfel</p>
            <div class="custom-select">
                <div class="selected" :class="{ 'no-border-radius': isDropdownOpen }" @click="toggleDropdown">
                    {{ selectedDateText || 'Wybierz termin' }}
                    <span class="arrow" :class="{ 'open': isDropdownOpen }">
                        <i class="fas fa-angle-down"></i>
                    </span>
                </div>
                <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                    <div class="options" v-if="isDropdownOpen">
                        <div class="option" v-for="(date, index) in billingCycles" :key="index"
                            @click="selectDate(date)" @mouseover="hoverIndex = index" @mouseleave="hoverIndex = null"
                            :class="{ hover: hoverIndex === index }">
                            {{ formatDate(date.start_date) }} - {{ formatDate(date.end_date) }}
                        </div>
                    </div>
                </transition>
            </div>
        </div>

        <div v-if="billingCycles.length > 0" class="earnings-summary">
            <div v-if="selectedDate !== null" class="section">
                <h3>Twoje zarobki</h3>

                <div v-for="(app, appName) in earnings" :key="appName" class="app-summary">
                    <div @click="toggleAppSummary(appName)" class="app-header">
                        <h4>{{ appDisplayNames[appName] }}</h4>
                        <i :class="{ 'fas fa-chevron-down arrow': true, 'open': expandedApp[appName] }"></i>
                    </div>
                    <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                        <div v-if="expandedApp[appName]" class="earnings-details" :id="'app-summary-' + appName">
                            <div><strong>Brutto:</strong> {{ formatAsPositive(app.gross) }} PLN</div>
                            <div><strong>Netto:</strong> {{ formatAsPositive(app.net) }} PLN</div>
                            <div><strong>Gotówka na stanie:</strong> {{ formatAsPositive(app.cash) }} PLN</div>
                            <div><strong>Faktury kosztowe:</strong> {{ formatAsPositive(app.invoices) }} PLN</div>
                            <div><strong>VAT dla Brutto:</strong> {{ formatAsPositive(calculateVat(app.gross, app.vatRate)) }} PLN</div>
                            <div><strong>Do rozliczenia:</strong> {{ formatAsPositive(app.net - app.cash) }} PLN</div>
                        </div>
                    </transition>
                </div>

                <div class="total-summary">
                    <h4>Łącznie</h4>
                    <div class="earnings-details">
                        <div><strong>Brutto:</strong> {{ roundToTwoDecimals(total.gross) }} PLN</div>
                        <div><strong>Netto:</strong> {{ roundToTwoDecimals(total.net) }} PLN</div>
                        <div><strong>Gotówka na stanie:</strong> {{ roundToTwoDecimals(total.cash) }} PLN</div>
                        <div><strong>Faktury kosztowe:</strong> {{ roundToTwoDecimals(total.invoices) }} PLN</div>
                        <div><strong>VAT do odliczenia:</strong> {{ roundToTwoDecimals(total.vat) }} PLN</div>
                        <div><strong>Do rozliczenia:</strong> {{ roundToTwoDecimals(total.toSettle) }} PLN</div>
                        <div class="settle-amount">
                            <strong>Kwota do obciążenia:</strong> {{ roundToTwoDecimals(total.toSettle - total.vat + total.invoices)}} PLN
                        </div>
                    </div>
                </div>

                <div class="app-summary">
                    <div @click="toggleTips" class="app-header">
                        <h4>Wskazówki</h4>
                        <i :class="{ 'fas fa-chevron-down arrow': true, 'open': areTipsOpen }"></i>
                    </div>
                    <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                        <div v-if="areTipsOpen" class="tips-details" :id="'tips-section'">
                            <div class="tip-block">
                                <h5>Zarobki</h5>
                                <p><strong>Brutto:</strong> Kwota całkowitych zarobków w danej aplikacji przed
                                    odliczeniem prowizji i kosztów.</p>
                                <p><strong>Netto:</strong> Kwota zarobków po odliczeniu prowizji aplikacji, ale przed
                                    opodatkowaniem.</p>
                            </div>
                            <div class="divider"></div>
                            <div class="tip-block">
                                <h5>VAT</h5>
                                <p><strong>VAT dla Brutto:</strong> Obliczany według wzoru: (Kwota Brutto × 8%) / 108.
                                </p>
                                <p><strong>VAT do Odliczenia:</strong> Kwota zmniejszająca kwotę końcową do obciążenia.
                                </p>
                            </div>
                            <div class="divider"></div>
                            <div class="tip-block">
                                <h5>Gotówka</h5>
                                <p><strong>Gotówka na Stanie:</strong> Kwota fizycznie posiadana przez kierowcę,
                                    uzyskana od pasażerów w formie gotówki.</p>
                                <p><strong>Do Rozliczenia:</strong> Kwota Netto pomniejszona o gotówkę w posiadaniu
                                    kierowcy.</p>
                            </div>
                            <div class="divider"></div>
                            <div class="tip-block">
                                <h5>Kwota do Obciążenia</h5>
                                <p>Finalna kwota po uwzględnieniu VAT-u z faktur kosztowych kierowcy (dodanie połowy
                                    VAT-u z faktur).</p>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: true,
            isLatestCycle: false,
            selectedBillingCycle: null,
            isDropdownOpen: false,
            hoverIndex: null,
            areTipsOpen: false,
            earnings: {},
            expandedApp: {
                bolt: false,
                uber: false,
                freenow: false
            },
        };
    },
    computed: {
        billingCycles() {
            return this.$store.state.billingCycles.filter(cycle => cycle.is_closed === true || cycle.is_closed === 1); // wyswietlamy tylko zamknięte cykle
        },
        selectedDateText() {
            return this.selectedBillingCycle ?
                `${this.formatDate(this.selectedBillingCycle.start_date)} - ${this.formatDate(this.selectedBillingCycle.end_date)}` : '';
        },
        appDisplayNames() {
            return {
                bolt: 'Bolt',
                uber: 'Uber',
                freenow: 'FreeNow'
            };
        },
        total() {
            const totalGross = Object.values(this.earnings).reduce((sum, app) => sum + Math.abs(app.gross), 0);
            const totalNet = Object.values(this.earnings).reduce((sum, app) => sum + Math.abs(app.net), 0);
            const totalCash = Object.values(this.earnings).reduce((sum, app) => sum + Math.abs(app.cash), 0);
            const totalInvoices = Object.values(this.earnings).reduce((sum, app) => sum + Math.abs(app.invoices), 0);

            const totalVat = this.calculateVat(totalGross, this.calculateAverageVat());
            const toSettle = totalNet - totalCash;

            const totalToCharge = toSettle - this.calculateInvoicesVat(totalInvoices);

            return {
                gross: totalGross,
                net: totalNet,
                cash: totalCash,
                invoices: totalInvoices,
                vat: totalVat,
                toSettle: toSettle,
                totalToCharge: totalToCharge
            };
        }
    },
    watch: {
        billingCycles: {
            handler(newBillingCycles) {
                if (newBillingCycles.length > 0 && !this.selectedBillingCycle) {
                    const latestCycle = newBillingCycles.reduce((latest, current) =>
                        new Date(current.start_date) > new Date(latest.start_date) ? current : latest
                    );
                    this.selectedBillingCycle = latestCycle;
                    this.selectDate(latestCycle);
                    this.setEarnings(latestCycle);
                }
                this.isLoading = false;
            },
            immediate: true,
        },
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        formatDate(date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        selectDate(date) {
            this.selectedBillingCycle = date;
            this.isDropdownOpen = false;

            const currentDate = new Date();
            this.isLatestCycle = new Date(date.end_date) >= currentDate;
            this.setEarnings(date);
        },
        setEarnings(cycle) {
            this.isLoading = true;
            const earningsData = {};

            cycle.billings.forEach(billing => {
                const appName = billing.application.toLowerCase();
                if (!earningsData[appName]) {
                    earningsData[appName] = {
                        gross: 0,
                        net: 0,
                        cash: 0,
                        invoices: 0,
                        vatRate: 8
                    };
                }
                earningsData[appName].gross += parseFloat(billing.gross);
                earningsData[appName].net += parseFloat(billing.net);
                earningsData[appName].cash += parseFloat(billing.cash_on_hand);
                earningsData[appName].invoices += parseFloat(billing.invoice_sum);
            });

            this.earnings = earningsData;
            this.isLoading = false;
        },
        toggleAppSummary(appName) {
            this.expandedApp[appName] = !this.expandedApp[appName];

            this.$nextTick(() => {
                if (this.expandedApp[appName]) {
                    const element = document.getElementById('app-summary-' + appName);
                    if (element) {
                        window.scrollTo({
                            top: element.offsetTop - 70,
                            behavior: 'smooth',
                        });
                    }
                }
            });
        },
        toggleTips() {
            this.areTipsOpen = !this.areTipsOpen;

            this.$nextTick(() => {
                if (this.areTipsOpen) {
                    const element = document.getElementById('tips-section');
                    if (element) {
                        window.scrollTo({
                            top: element.offsetTop - 70,
                            behavior: 'smooth',
                        });
                    }
                }
            });
        },
        calculateVat(grossAmount, vatRate) {
            return ((grossAmount * vatRate) / 108).toFixed(2);
        },
        calculateAverageVat() {
            const vatRates = Object.values(this.earnings).map(app => app.vatRate);
            return vatRates.reduce((sum, rate) => sum + rate, 0) / vatRates.length;
        },
        calculateInvoicesVat(invoices) {
            return invoices * 0.23;
        },
        roundToTwoDecimals(value) {
            return parseFloat(value).toFixed(2);
        },
        formatAsPositive(value) {
            return Math.abs(value).toFixed(2);
        },
        beforeEnter(el) {
            el.style.opacity = 0;
            el.style.transform = 'translateY(-10px)';
        },
        enter(el, done) {
            el.offsetHeight;
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 1;
            el.style.transform = 'translateY(0)';
            done();
        },
        leave(el, done) {
            el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
            el.style.opacity = 0;
            el.style.transform = 'translateY(-10px)';
            done();
        }
    }
};
</script>

<style lang="scss" scoped>
.wallet {
    margin: 0 auto;
    padding: 20px;
    font-size: 18px;
    color: $white;
    font-family: 'Roboto-Light', sans-serif;
    height: auto;

    &>.no-cycle-message {
        display: block;
    }
}

.wallet:has(.no-cycle-message) {
    height: 100vh;
}


.title {
    text-align: left;
    color: $white;
}

.billing-cycle {
    display: flex;
    align-items: center;
    gap: 20px;
}

.custom-select {
    position: relative;
    width: 300px;
    color: $white;
}

.selected {
    padding: 10px;
    font-size: 16px;
    font-family: 'Roboto-Light', sans-serif;
    background-color: $secondary-color;
    color: $placeholder-color;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border-radius 0.3s ease;

    &.no-border-radius {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.arrow {
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 14px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    background-color: $secondary-color;
    border-radius: 0 0 10px 10px;
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);
}

.option {
    padding: 10px;
    cursor: pointer;
    color: $white;

    &:hover {
        background-color: $tr_color;
    }
}

.earnings-summary {
    margin-top: 20px;
    color: $white;
}

.section {
    margin-bottom: 30px;
}

.app-summary {
    margin-bottom: 15px;
}

.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: $secondary-color;
    padding: 10px;
    border-radius: 8px;
    color: $white;
}

.app-header i {
    margin-right: 5px;
    transition: transform 0.3s ease;
}

.app-summary h4 {
    margin: 0;
    font-size: 20px;
}

.earnings-details {
    background-color: $tr_color;
    margin-top: 15px;
    color: $white;
    font-size: 16px;
    padding: 15px;
    border-radius: 8px;
}

.earnings-details:last-of-type {
    margin-bottom: 15px;
}

.total-summary {
    background-color: $tr_color;
    border-radius: 8px;
}

.total-summary h4 {
    margin: 0;
    font-size: 20px;
    color: $white;
    padding-left: 15px;
    padding-top: 15px;
}

.total-summary .earnings-details {
    padding-top: 0px;
    font-size: 16px;
}

.total-summary .earnings-details .settle-amount {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: $primary-color;
    border-top: 2px solid $primary-color;
    padding-top: 10px;
}

.tips-details {
    background-color: $tr_color;
    margin-top: 15px;
    padding: 15px;
    border-radius: 8px;
    font-size: 16px;
    color: $white;

    .tip-block {
        margin-bottom: 15px;

        h5 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
            color: $primary-color;
        }

        p {
            margin: 5px 0;
            line-height: 1.5;
        }
    }

    .divider {
        height: 2px;
        margin: 25px auto;
        background: $primary-color;
        border-radius: 1px;
    }
}

.wallet-loader {
    padding: 20px;
}

.billing-cycle-loader,
.summary-section-loader,
.upload-wallet-loader,
.invoice-status-loader {
    margin-bottom: 20px;
}

.placeholder-box {
    width: 100%;
    height: 30px;
    background-color: $placeholder-color;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

.placeholder-box:nth-child(1) {
    width: 80%;
}

.placeholder-box:nth-child(2) {
    width: 60%;
}

.placeholder-box:nth-child(3) {
    width: 90%;
}

.placeholder-box:after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
    animation: shine 1.5s infinite ease-in-out;
}

@keyframes shine {
    0% {
        left: -100%;
    }

    50% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}

.no-cycle-message {
    font-size: 18px;
    color: $white;
    margin-top: 20px;
    text-align: center;
}
</style>