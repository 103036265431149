<template>
  <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
    <div v-if="isModalOpen" class="modal" @click.self="closeModal">
      <div class="modal-content">
        <img :src="'data:image/jpeg;base64,' + modalImageBase64" alt="Podgląd faktury" />
        <button class="close-button" @click="closeModal">Zamknij</button>
      </div>
    </div>
  </transition>
  <div v-if="isLoading" class="invoices-loader">
    <div class="billing-cycle-loader">
      <div class="placeholder-box"></div>
      <div class="placeholder-box"></div>
    </div>
    <div class="summary-section-loader">
      <div class="placeholder-box"></div>
      <div class="placeholder-box"></div>
      <div class="placeholder-box"></div>
    </div>
    <div class="upload-invoice-loader">
      <div class="placeholder-box"></div>
      <div class="placeholder-box"></div>
      <div class="placeholder-box"></div>
      <div class="placeholder-box"></div>
    </div>
    <div class="invoice-status-loader">
      <div class="placeholder-box"></div>
      <div class="placeholder-box"></div>
    </div>
  </div>
  <div v-else class="invoices">
    <div class="billing-cycle">
      <p class="title">Faktura</p>
      <div class="custom-select">
        <div class="selected" :class="{ 'no-border-radius': isDropdownOpen }" @click="toggleDropdown">
          {{ selectedDateText || 'Wybierz termin' }}
          <span class="arrow" :class="{ 'open': isDropdownOpen }">
            <i class="fas fa-angle-down"></i>
          </span>
        </div>
        <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div class="options" v-if="isDropdownOpen">
            <div class="option" v-for="(date, index) in billingCycles" :key="index" @click="selectDate(date)"
              @mouseover="hoverIndex = index" @mouseleave="hoverIndex = null" :class="{ hover: hoverIndex === index }">
              {{ formatDate(date.start_date) }} - {{ formatDate(date.end_date) }}
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="summary-section" v-if="acceptedInvoices.length">
      <p class="summary-title">Podsumowanie zaakceptowanych faktur:</p>
      <div class="summary-details">
        <p class="summary">Łączna kwota netto: {{ totalNetAmount }} PLN</p>
        <p class="summary">Łączna kwota VAT: {{ totalVatAmount }} PLN</p>
        <p class="summary">Łączna kwota brutto: {{ totalGrossAmount }} PLN</p>
      </div>
    </div>
    <div class="add-invoice" v-if="isLatestCycle">
      <p class="title">Wgraj fakturę</p>
      <div class="upload-invoice">
        <label for="file" class="custum-file-upload">
          <div class="icon">
            <svg class="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" />
            </svg>
          </div>
          <div class="text">
            <span v-if="!fileName">Kliknij, aby wgrać plik</span>
            <span v-else>{{ fileName }}</span>
          </div>
          <input id="file" type="file" @change="handleFileUpload" ref="fileInput" />
        </label>
      </div>
      <div class="invoice-data">
        <p class="title">Opis faktury</p>
        <div class="input-row">
          <input type="text" placeholder="RRRR-MM-DD" class="input-field" ref="invoiceDateInput" />
          <div class="custom-select" @click="toggleVatDropdown">
            <div class="selected" :class="{ 'no-border-radius': isVatDropdownOpen }">
              {{ selectedVatText || 'Stawka VAT' }}
              <span class="arrow" :class="{ 'open': isVatDropdownOpen }">
                <i class="fas fa-angle-down"></i>
              </span>
            </div>
            <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
              <div class="options" v-if="isVatDropdownOpen">
                <div class="option" v-for="(vat, index) in vatRates" :key="index"
                  @click="(event) => { selectVat(index); event.stopPropagation(); }" @mouseover="hoverVatIndex = index"
                  @mouseleave="hoverVatIndex = null" :class="{ hover: hoverVatIndex === index }">
                  {{ vat }}
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="input-row">
          <input type="text" placeholder="Kwota VAT" class="input-field" ref="vatAmountInput" />
          <input type="text" placeholder="Kwota NETTO" class="input-field" ref="netAmountInput" />
        </div>
        <div class="input-row">
          <input type="text" placeholder="Kwota BRUTTO" class="input-field" ref="grossAmountInput" />
          <div class="custom-select" @click="toggleAppDropdown">
            <div class="selected" :class="{ 'no-border-radius': isAppDropdownOpen }">
              {{ selectedAppText || 'Aplikacja' }}
              <span class="arrow" :class="{ 'open': isAppDropdownOpen }">
                <i class="fas fa-angle-down"></i>
              </span>
            </div>
            <transition name="dropdown" @before-enter="beforeEnter" @enter="enter" @leave="leave">
              <div class="options" v-if="isAppDropdownOpen">
                <div class="option" v-for="(app, index) in applications" :key="index"
                  @click="(event) => { selectApp(index); event.stopPropagation(); }" @mouseover="hoverAppIndex = index"
                  @mouseleave="hoverAppIndex = null" :class="{ hover: hoverAppIndex === index }">
                  {{ app }}
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="fileName" class="upload-button-container">
          <button class="upload-button" @click="submitInvoice">Wyślij</button>
        </div>
      </div>
    </div>
    <div v-else class="title">
      <p>Ten cykl już minął. Nie możesz dodać faktury do tego cyklu.</p>
    </div>
    <div class="invoices-status">
      <p class="title">Status dodanych Faktur</p>
      <p v-if="invoices.length === 0" class="no-invoices">Brak faktur</p>
      <div v-else class="invoice" v-for="(invoice, index) in invoices" :key="index">
        <span class="invoice-number" @click="openModal(invoice.image_base64)">Faktura z {{ invoice.invoice_date
          }}</span>
        <span :class="`invoice-status status-${invoice.status}`">{{ invoice.status }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      isLatestCycle: false,
      selectedBillingCycle: null,
      isDropdownOpen: false,
      hoverIndex: null,
      vatRates: ['8', '23'],
      applications: [],
      selectedVat: null,
      selectedApp: null,
      isVatDropdownOpen: false,
      isAppDropdownOpen: false,
      hoverVatIndex: null,
      hoverAppIndex: null,
      fileName: null,
      isModalOpen: false,
      modalImageBase64: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    user() {
      return this.getUser || {};
    },
    billingCycles() {
      return this.$store.state.billingCycles;
    },
    invoices() {
      return this.$store.state.invoices;
    },
    selectedDateText() {
      return this.selectedBillingCycle ?
        `${this.formatDate(this.selectedBillingCycle.start_date)} - ${this.formatDate(this.selectedBillingCycle.end_date)}` : '';
    },
    selectedVatText() {
      return this.selectedVat !== null ? this.vatRates[this.selectedVat] : '';
    },
    selectedAppText() {
      return this.selectedApp !== null ? this.applications[this.selectedApp] : '';
    },
    acceptedInvoices() {
      if (this.invoices) {
        return this.invoices.filter(invoice => invoice.status === 'Zaakceptowana');
      }
      return [];
    },
    totalNetAmount() {
      return this.acceptedInvoices.reduce((total, invoice) => total + parseFloat(invoice.net_amount || 0), 0).toFixed(2);
    },
    totalVatAmount() {
      return this.acceptedInvoices.reduce((total, invoice) => total + parseFloat(invoice.vat_amount || 0), 0).toFixed(2);
    },
    totalGrossAmount() {
      return this.acceptedInvoices.reduce((total, invoice) => total + parseFloat(invoice.gross_amount || 0), 0).toFixed(2);
    },
    applicationsList() {
      const user = this.user;

      const apps = [];

      if (user.bolt_driver) apps.push('Bolt');
      if (user.uber_driver) apps.push('Uber');
      if (user.freenow_driver) apps.push('FreeNow');

      return apps;
    }
  },
  watch: {
    billingCycles: {
      handler(newBillingCycles) {
        if (newBillingCycles.length > 0 && !this.selectedBillingCycle) {
          const latestCycle = newBillingCycles.reduce((latest, current) =>
            new Date(current.start_date) > new Date(latest.start_date) ? current : latest
          );
          this.selectedBillingCycle = latestCycle;
          this.selectDate(latestCycle);
        }
      },
      immediate: true,
    },
    applicationsList(newValue) {
      this.applications = newValue;
    }
  },
  methods: {
    ...mapActions(['createInvoice']),
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    selectDate(date) {
      this.selectedBillingCycle = date;
      this.isDropdownOpen = false;

      const currentDate = new Date();
      this.isLatestCycle = new Date(date.end_date) >= currentDate;
      this.isLoading = true;
      this.$store.dispatch('fetchInvoicesByBillingCycle', { billingCycleId: date.id })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Błąd ładowania faktur:', error);
          this.isLoading = false;
        });
    },
    toggleVatDropdown() {
      this.isVatDropdownOpen = !this.isVatDropdownOpen;
    },
    selectVat(index) {
      this.selectedVat = index;
      this.isVatDropdownOpen = false;
    },
    toggleAppDropdown() {
      this.isAppDropdownOpen = !this.isAppDropdownOpen;
    },
    selectApp(index) {
      this.selectedApp = index;
      this.isAppDropdownOpen = false;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileBase64 = reader.result.replace(/^data:image\/[a-z]+;base64,/, '');
        };
        reader.onerror = (error) => {
          console.error('Error loading file:', error);
        };
      }
    },
    async submitInvoice() {
      try {
        const invoiceData = {
          // billing_cycle_id: this.selectedBillingCycle,
          vat_rate: this.vatRates[this.selectedVat],
          application: this.applications[this.selectedApp],
          vat_amount: this.$refs.vatAmountInput.value.trim(),
          net_amount: this.$refs.netAmountInput.value.trim(),
          gross_amount: this.$refs.grossAmountInput.value.trim(),
          invoice_date: this.$refs.invoiceDateInput.value.trim(),
          image_base64: this.fileBase64,
          status: 'Weryfikacja',
        };
        await this.$store.dispatch('createInvoice', invoiceData);
        Swal.fire({
          icon: 'success',
          title: 'Sukces',
          text: 'Faktura została poprawnie dodana i oczekuje na weryfikację!',
        });
        this.clearForm();
      } catch (error) {
        console.error('Błąd dodania faktury:', error);
        const errorMessage = error.response?.data?.message || 'Wystąpił problem podczas dodawania faktury. Spróbuj ponownie.';
        Swal.fire({
          icon: 'error',
          title: 'Błąd',
          text: errorMessage,
        });
      }
    },
    clearForm() {
      this.$refs.invoiceDateInput.value = '';
      this.$refs.vatAmountInput.value = '';
      this.$refs.netAmountInput.value = '';
      this.$refs.grossAmountInput.value = '';
      this.selectedVat = null;
      this.selectedApp = null;
      this.fileName = null;
      this.fileBase64 = null;
      this.$refs.fileInput.value = '';
    },
    openModal(imageBase64) {
      this.modalImageBase64 = imageBase64;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.modalImageBase64 = null;
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.offsetHeight;
      el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
      el.style.opacity = 0;
      setTimeout(() => {
        done();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.invoices {
  margin: 0 auto;
  padding: 20px;
  font-size: 18px;
  color: $white;
}

.title,
.no-invoices,
.summary-title {
  text-align: left;
  font-family: 'Roboto-Light', sans-serif;
  color: $white;
}

.summary-title {
  margin-top: 0;
}

.no-invoices {
  font-size: 16px;
}

.billing-cycle {
  display: flex;
  align-items: center;
  gap: 20px;
}

.custom-select {
  position: relative;
  width: 300px;
  color: $white;
}

.selected {
  padding: 10px;
  font-size: 16px;
  font-family: 'Roboto-Light', sans-serif;
  background-color: $secondary-color;
  color: $placeholder-color;
  border: 2px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-radius 0.3s ease;

  &.no-border-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  font-size: 14px;
  font-family: 'Roboto-Extra-Light', sans-serif;
  background-color: $secondary-color;
  border-radius: 0 0 10px 10px;
  z-index: 1;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2), 0 -2px 4px rgba(0, 0, 0, 0);
}

.option {
  padding: 10px;
  cursor: pointer;
  color: $white;

  &:hover {
    background-color: $tr_color;
  }
}

.upload-invoice {
  display: flex;
  flex-direction: column;
}

.upload-button {
  margin: 20px 0px;
  padding: 10px 20px;
  font-size: 16px;
  color: $white;
  background-color: $primary-color;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: $text-container-color;
    color: $primary-color;
  }
}

.upload-button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.custum-file-upload {
  font-family: 'Roboto-Light', sans-serif;
  margin: 20px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: $text-container-color;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 25px;
      fill: $white;
      transition: fill 0.3s ease;
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $white;
    transition: all 0.3s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    display: none;
  }
}

.custum-file-upload:hover {
  background-color: $primary-color;
}

.custum-file-upload:hover .svg-icon {
  fill: $tertiary-color;
}

.custum-file-upload:hover .text {
  color: $tertiary-color;
}

.input-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.input-field {
  padding: 10px;
  width: 300px;
  font-size: 16px;
  font-family: 'Roboto-Light', sans-serif;
  border: 2px solid transparent;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: $secondary-color;
  color: $placeholder-color;
  transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  outline: none;
}

.invoices-status {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 600px;
}

.invoice {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: $white;
  font-family: 'Roboto-Light', sans-serif;
  border-bottom: 1px solid $background-color;
  font-size: 16px;
}

.invoice-number {
  margin-right: auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.invoice-number:hover {
  color: $primary-color;
}

.invoice-status {
  border-radius: 15px;
  font-weight: bold;
}

.status-Weryfikacja {
  color: $primary-color;
}

.status-Zaakceptowana {
  color: $success-color;
}

.status-Odrzucona {
  color: $warning-color;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: transparent;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
  margin-bottom: 10px;
}

.close-button {
  padding: 8px 16px;
  background-color: $text-container-color;
  color: $white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close-button:hover {
  background-color: $remind-password;
}

.summary-section {
  margin-top: 20px;
  padding: 20px;
  background-color: $secondary-color;
  border-radius: 15px;
  color: $white;
  font-family: 'Roboto-Light', sans-serif;

  .title {
    font-size: 18px;
  }

  .summary-details {
    font-size: 16px;
    line-height: 0.9;
  }

  .summary {
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .input-row {
    flex-direction: column;
  }

  .input-field {
    width: 100%;
  }

  .custom-select {
    width: 100%;
  }

  .upload-invoice {
    align-items: center;
  }

  .upload-button-container {
    justify-content: center;
  }
}

.invoices-loader {
  padding: 20px;
}

.billing-cycle-loader,
.summary-section-loader,
.upload-invoice-loader,
.invoice-status-loader {
  margin-bottom: 20px;
}

.placeholder-box {
  width: 100%;
  height: 30px;
  background-color: $placeholder-color;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.placeholder-box:nth-child(1) {
  width: 80%;
}

.placeholder-box:nth-child(2) {
  width: 60%;
}

.placeholder-box:nth-child(3) {
  width: 90%;
}

.placeholder-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shine 1.5s infinite ease-in-out;
}

@keyframes shine {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}
</style>