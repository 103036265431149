<template>
    <div class="settlements">
        <div class="text-container">
            <p class="title" :class="{ 'is-visible': isTitleVisible }">ROZLICZENIA</p>
            <p class="description" v-html="description" :class="{ 'is-visible': isDescriptionVisible }"></p>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const description = ref("Oferujemy elastyczne rozliczenia oparte na jasnych i przejrzystych zasadach. Zyskujesz czytelny dostęp do szczegółowych danych o swoich zarobkach, wraz z objaśnieniem sposobu ich obliczania. <br><br>Dzięki zautomatyzowanemu systemowi zapewniamy terminowe przelewy oraz precyzyjne obliczanie podatku ryczałtowego. Prostota i wygoda – rozliczenia, które działają dla Ciebie!");

const isTitleVisible = ref(false);
const isDescriptionVisible = ref(false);

onMounted(() => {
    const titleObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            isTitleVisible.value = true;
        }
    }, { threshold: 0.2 });

    const descriptionObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            isDescriptionVisible.value = true;
        }
    }, { threshold: 0.2 });

    const titleElement = document.querySelector('.title');
    if (titleElement) {
        titleObserver.observe(titleElement);
    }

    const descriptionElement = document.querySelector('.description');
    if (descriptionElement) {
        descriptionObserver.observe(descriptionElement);
    }
});
</script>

<style lang="scss" scoped>
.settlements {
    background-color: $secondary-color;
    display: flex;
    justify-content: left;
}

.text-container {
    padding: 0 0 50px 50px;
    text-align: left;
}

.title {
    font-size: 36px;
    font-family: 'Roboto-Light', sans-serif;
    color: $primary-color;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.title.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.description {
    width: 80%;
    font-size: 22px;
    font-family: 'Roboto-Extra-Light', sans-serif;
    color: $title-light-font;
    text-align: justify;
    text-justify: inter-word;
    word-wrap: break-word;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.description.is-visible {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 768px) {
    .text-container {
        padding: 0 20px 50px 20px;
    }

    .title {
        font-size: 28px;
    }

    .description {
        width: 100%;
        font-size: 18px; 
    }
}
</style>