<template>
  <div :key="uniqueKey" class="welcome-page">
    <div class="logo fade-in">
      <img src="@/assets/images/cabbie-logo.svg" alt="cabbie" />
    </div>
    <div class="text-content fade-in">
      <p class="title">ZAUFANY PARTNER KIEROWCÓW</p>
      <p class="subtitle">BOLT, UBER I FREE NOW</p>
    </div>
    <button class="lets-begin fade-in" @click="scrollToGoal">POZNAJMY SIĘ</button>
  </div>
</template>

<script>
export default {
  name: 'WelcomePage',
  data() {
    return {
      uniqueKey: Date.now(),
    };
  },
  methods: {
    scrollToGoal() {
      const goalElement = document.getElementById('offer');
      if (goalElement) {
        const rect = goalElement.getBoundingClientRect();
        window.scrollTo({
          top: window.pageYOffset + rect.top,
          behavior: 'smooth',
        });
      }
    },
  },
  mounted() {
    const elements = document.querySelectorAll('.fade-in');

    elements.forEach((el) => {
      el.classList.remove('visible');
    });

    elements.forEach((el, index) => {
      setTimeout(() => {
        el.classList.add('visible');
      }, index * 200); 
    });
  },
};
</script>

<style lang="scss" scoped>
.welcome-page {
  background-image: url('@/assets/images/driver-dressed-elegant-costume 1-placeholder.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.fade-in {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.logo {
  background-color: $primary-color;
  height: 20vmin;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.logo img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.text-content {
  text-align: center;
  margin-top: 40px;
}

.title {
  color: $white;
  text-align: center;
  font-family: 'Roboto-Light', 'sans-serif';
  font-size: 36px;
  margin: 0;
}

.subtitle {
  color: $white;
  font-family: 'Roboto-Light', 'sans-serif';
  font-size: 24px;
  margin-top: 10px;
}

.lets-begin {
  background-color: transparent;
  border: 2px solid $primary-color;
  border-radius: 8px;
  color: $primary-color;
  cursor: pointer;
  font-family: 'Roboto-Light', 'sans-serif';
  font-size: 28px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lets-begin:hover {
  color: $tertiary-color;
  background-color: $primary-color;
  border-color: $tertiary-color;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .logo {
    height: 40vmin;
    margin-top: 8vh;
  }

  .logo img {
    max-height: 75%;
    max-width: 75%;
    height: auto;
    width: auto;
  }

  .text-content {
    margin-top: 40px;
  }

  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 20px;
  }

  .lets-begin {
    font-size: 24px;
    margin-top: 30px;
  }
}
</style>